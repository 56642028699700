import React, { Component } from "react";
import {Screen, isValidScreen, InvalidScreenSelectionException} from "../Authentication/Commons";
import CaptchaService from "../../services/captcha-service";
import SignInComponent from "./SignInComponent";
import RegistrationComponent from "./RegistrationComponent";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import ProfileSettingsModal from "./ProfileSettingsComponent";
import utilities from "support/utilities";

class ModalFlowManagement extends React.Component {
	constructor(props) {
		super(props)
		let activeScreen = Screen.REGISTRATION;
		if(isValidScreen(props.mode)) {
			activeScreen = props.mode;
		}
		this.state = {
			activeScreen: activeScreen,
			isCaptchaEnabled: false,
			...props
		}
	}


	setFocusInModal(){
		const  focusableElements ='button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
		const modal = document.querySelector('auth-modal');
		const captcha = document.querySelector('[title="reCAPTCHA"]');
		const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
		const focusableContent = modal.querySelectorAll(focusableElements);
		const lastFocusableElement = focusableContent[focusableContent.length - 1];
		const beforeLastFocusableElement = focusableContent[focusableContent.length - 2];
		document.addEventListener('keydown', function(e) {
			let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
			if (!isTabPressed) return;
		
			if (e.shiftKey) { 
				if (document.activeElement === firstFocusableElement) {
					if(lastFocusableElement.disabled){
						beforeLastFocusableElement.focus(); 
						e.preventDefault();
					}else{
						lastFocusableElement.focus(); 
				  		e.preventDefault();
					}
				  
				}
			} else {
				if (document.activeElement === lastFocusableElement) { 
					firstFocusableElement.focus();
					e.preventDefault();
				}
			  }
		});
		$(document).on('focusin', function (e) {
			var $target = $(e.target);
			if (!$target.closest('auth-modal').length) {
				firstFocusableElement.focus();
				e.preventDefault();
			}
		});
		firstFocusableElement.focus();
	}

	componentDidMount() {
		this.setFocusInModal();
		this.handleRecaptchaNotice();
	}

	componentDidUpdate(prevProps, prevState) {
		this.setFocusInModal();
	}

	handleClose = (ev) => {
		ev.preventDefault();
		this.props.onClose();
		if (this.state.activeScreen === Screen.PROFILE_SETTINGS) {
			window.location.reload();
		}
	};

	handleRecaptchaNotice = () => {
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled && this.state.activeScreen != Screen.PROFILE_SETTINGS) {
					this.setState({isCaptchaEnabled: true});
				}
			});
	}
	
	render() {
		let Component = this.identifyScreen(this.state.activeScreen)
		let overlayClass = this.state.activeScreen == Screen.REGISTRATION ? "hbr-modal-overlay active center-medium-up" : "hbr-modal-overlay active center"
		let className = `hbr-modal-wrapper ${this.state.activeScreen.toLocaleLowerCase()}`
		return (
			<div class={overlayClass}>
				<div class={className}>
					<div class="hbr-modal">
						<div class="hbr-modal--header">
							<a href="#" onClick={this.handleClose}>
								<img class="hbr-modal--close"
									src="/resources/css/images/close.svg" />
							</a>
						</div>
						<div class="hbr-modal--body">
							<Component sendTo={this.sendTo.bind(this)} onClose={this.props.onClose} fromPaywall={this.props.fromPaywall} />
							{this.state.isCaptchaEnabled && <div class="recaptcha-privacy-notice">
								This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
							</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
	
	sendTo(screen) {
		if(isValidScreen(screen)) {
			this.setState({
				activeScreen: screen
			})
		} else {
			throw new InvalidScreenSelectionException(screen);
		}
	}
	
	identifyScreen(activeScreen) {
		switch(activeScreen) {
			case Screen.SIGN_IN:
				return SignInComponent
			case Screen.REGISTRATION:
				utilities.trigger("metrics:registration_start");
				return RegistrationComponent
			case Screen.FORGOT_PASSWORD:
				return ForgotPasswordComponent
			case Screen.PROFILE_SETTINGS:
				return ProfileSettingsModal
		}
	}
}

export default ModalFlowManagement;
