import $ from "jquery";
import common from "../common";
import request from "./request";

var API_ENDPOINTS = {
	AUTHENTICATE: "/authenticate",
	LOGOUT: "/logout",
	PROFILE: common.API + "user/profile",
	QUOTA: common.API + "user/quota",
	ASCEND_QUOTA: common.API + "user/ascend-quota",
	CREATE: common.API + "user/register",
	UPDATE_PASSWORD: common.API + "user/password",
	FORGOT_PASSWORD: common.API + "user/forgot-password",
	RESET_PASSWORD: common.API + "user/reset-password",
	CREATE_NEW: "/api/user/register",
	PAYWALL_FORGOT_PASSWORD: "/api/user/forgotpassword",
	CREATE_NEW_PIANO: "/api/user/subscriber/register"
};
/**
 * Makes an POST request to the `user/auth` endpoint to log in a user.
 *
 * @param {string} username - something that identifies the user.
 * @param {string} password - something that verifies the user.
 * @param {string} captchaToken - The captcha token for security.
 *
 * @returns {promise.<object, Error>} - Promise resolves with the user
 *   object, and rejects with an error if logging in fails.
 */
exports.login = function (username, password, captchaToken, deviceType="") {
	return $.ajax({
		type: "post",
		url: API_ENDPOINTS.AUTHENTICATE,
		dataType: "json",
		data: {
			username: username,
			password: password,
			reCaptchaToken: captchaToken,
			deviceType
		}
	});
};

/**
 * AJAX based logout.
 * Makes a GET request to the `user/logout` endpoint to log out the user.
 *
 * @returns {promise.<object, Error>} - Promise resolves with the user
 *   object, and rejects with an error if logging out fails.
 */
exports.logout = function () {
	return $.get(API_ENDPOINTS.LOGOUT);
};

/**
 * Redirects to the Logout end point.  Used when you want to perform
 * a full logout, including page redirection.
 */
exports.logoutRedirect = function () {
	/* get the current path and redirect back */
	var path = window.location.pathname;
	window.location.href = API_ENDPOINTS.LOGOUT + "?return=" + path;
};

/**
 * AJAX based logout.
 * Makes a POST request to the ms-user `logout` endpoint
 */
exports.logoutMsUser = function () { 
	const baseURL = window.msUserDomain;

	$.ajax({
		method: "POST",
		url: `${baseURL}/logout`,
		headers: {
			Accept: "*/*",
			"Content-Type": "application/json",
		},
		xhrFields: {
		   withCredentials: true
		}
	 });
}

exports.getUserModifiedTime = function () {
	return $.ajax({
		type: "head",
		url: API_ENDPOINTS.PROFILE
	}).then(function (data, status, response) {
		return response.getResponseHeader("Last-Modified");
	});
};
/**
 * getUser
 * @returns {object}
 */
exports.getUser = function () {
	// TODO: send a new request to the profile endpoint to get the
	// user object, maybe synchronous? if not this will
	// have to return a promise that resolves with updated userProfile.
	// TODO: update local userProfile object if server has more recent data
	//return userProfile;
	return $.getJSON(API_ENDPOINTS.PROFILE);
};

/**
 * getUserQuota
 * @returns {object}
 */
exports.getUserQuota = function () {
	return $.getJSON(API_ENDPOINTS.QUOTA);
};

/**
 * getUserAscnedQuota
 * @returns {object}
 */
exports.getUserAscendQuota = function () {
	return $.getJSON(API_ENDPOINTS.ASCEND_QUOTA);
};

/**
 * updateUser
 * @param {object} attrs - data to update
 * @returns {promise<object, Error>} a promise with the updated user
 */
exports.updateUser = function (attrs) {
	return request.ajax("post", API_ENDPOINTS.PROFILE, attrs).then(
		function (data) {
			return data;
		});
};

/**
 * createUser - Create a new User
 * @param {object} attrs - new user attributes
 * @returns {promise.<object, Error>} - promise that resolves the new user
 */
exports.createUser = function (attrs) {
	return request.ajax("post", API_ENDPOINTS.CREATE, attrs).then(
		function (data) {
			return data;
		});
};

exports.createNewUser = function (attrs) {
	return request.ajax("post", API_ENDPOINTS.CREATE_NEW, attrs).then(
		function (data) {
			return data;
		});
};

exports.createNewUserPiano = function (attrs) {
	const id = Math.floor(Math.random() * 999900 + 100);
	attrs.id = id;
	return request
		.ajax("post", API_ENDPOINTS.CREATE_NEW_PIANO, attrs)
		.then((data, status, xhr) => {
			const response = parseInt(xhr.getResponseHeader("x-amz-cf-stats"), 10);
			return {user: data,  header: (id + 173) === response};
		});
};
/**
 * changeUserPassword - Change the User Password
 * @param {string} current - Current Password
 * @param {string} password - New Password
 * @returns {promise.<object, Error>} - promise that resolves the update request.
 */
exports.changeUserPassword = function (current, password) {
	return request.ajax("post", API_ENDPOINTS.UPDATE_PASSWORD,
		{current: current, password: password});
};

/**
 * forgotUserPassword - Send an email to the user with the password request
 * @param {string} email - Email
 * @returns {promise.<object, Error>} - promise that resolves the request
 */
exports.forgotUserPassword = function (email) {
	return $.post(API_ENDPOINTS.FORGOT_PASSWORD, {
		email: email
	});
};

/**
 * resetUserPassword - Reset the User Password
 * @param {string} password - New Password
 * @returns {promise.<object, Error>} - promise that resolves the update request.
 */
exports.resetUserPassword = function (password, token) {
	return $.post(API_ENDPOINTS.RESET_PASSWORD, {
		password: password,
		token: token
	});
};

exports.verifyForgotPassword = function (email, captchaToken, deviceType="") {
	var params = {
		emailAddress: email,
		reCaptchaToken: captchaToken,
		deviceType
	};
	return $.ajax({
		type: "post",
		url: API_ENDPOINTS.PAYWALL_FORGOT_PASSWORD,
		data: JSON.stringify(params),
		contentType: "application/json"
	}).promise();
};

exports.verifySubscription = function (userDetail, endpointURL) {
	var requestData = {
		emailAddress: userDetail.emailAddress,
		accountNo: userDetail.accountNo,
		password: userDetail.password,
		optIn: userDetail.optIn
	};
	return $.ajax({
		type: "post",
		url: endpointURL,
		data: JSON.stringify(requestData),
		contentType: "application/json"
	});
};
