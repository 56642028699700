import create from "zustand"

var initialForm = {
	email: "",
	password: "",
	captcha: ""
}

var initialState = {
	form: {
		...initialForm
	},
	errors: {
		...initialForm,
	},
	errClearTrigger: {
		...initialForm,
	},
	pristine: {
		email: true,
		password: true,
	},
	valid: false,
	isLoading: false
}

function willFormBeValid(state, fieldName, fieldError) {
	// All other fields are not pristine
	const isNotPristine = Object.entries(state.pristine).every(
		([key, value]) => {
			if (value === false || key === fieldName) return true;
			return false;
		}
	);

	// All other fields have no errors
	const hasNoErrors = Object.entries(state.errors).every(([, value]) => {
		if (value !== "") return false;
		return true;
	});

	// This fields are not pristine and have no error
	return isNotPristine && hasNoErrors && fieldError === "";
}

const signInStore = create((set, get) => ({
	...initialState,
	reset: () => set(() => initialState),
	updateValue: (fieldName, value, error) => {
		set((state) => {
			let {
				errors,
				errClearTrigger
			} = state;
			// Clear errors that are related to this field
			Object.entries(errClearTrigger).forEach(([target, cleanOnArr]) => {
				if (cleanOnArr.includes(fieldName)) {
					errors[target] = initialForm[target];
					errClearTrigger[target] = [];
					$("[js-target='email']").removeClass("error-border");
				}
			});
			// If field has an error then add the clean trigger
			if (error !== "") {
				errClearTrigger = {
					...errClearTrigger,
					[fieldName]: [fieldName],
				};
				errors = {
					...errors,
					[fieldName]: error,
				};
			}
			const newState = {
				form: {
					...state.form,
					[fieldName]: value,
				},
				errors: {
					...errors,
				},
				errClearTrigger: {
					...errClearTrigger,
				},
				pristine: {
					...state.pristine,
					[fieldName]: false,
				},
				valid: willFormBeValid(state, fieldName, error),
			};
			return newState;
		});
	},
	updateError(fieldName, error, clearOn) {
		set((state) => ({
			errors: {
				...state.errors,
				[fieldName]: error,
			},
			errClearTrigger: {
				...state.errClearTrigger,
				[fieldName]: clearOn,
			},
			valid: willFormBeValid(state, fieldName, error),
		}));
	},
	setLoading: (submitting) => {
		set(state => ({
			isLoading: submitting
		}))
	}
}))

export default signInStore
