
// Components.
import "../components/common";
import $ from "jquery";
import "../support/digital-data/handler/landing-pages";

// update digital data
const pathName = window.location.pathname.toLowerCase();
if (pathName.indexOf("/email-newsletters") === 0) {
	$("body").trigger("set-onsite-results-count", $("follow-subscribe").length);
}

// initialize carousel
// slick carousel selector
var carouselTarget = ".slick-carousel"

// initialize slick carousel for mobile screens
if (isCarouselEligible()) {
	loadCarousel();
}

function loadCarousel() {
	if ($(carouselTarget).length > 0) {
		$(carouselTarget).slick({
			arrows: true,
			infinite: false,
			focusOnSelect: true,
			slidesToScroll: 1,
			nextArrow:
				"<i class='series-page__filterby-arrows series-page__filterby-arrows--right'></i>",
			prevArrow:
				"<i class='series-page__filterby-arrows series-page__filterby-arrows--left'></i>",
			variableWidth: true,
			swipe: true
		});
	}
}

// listener for screen resizing
window.addEventListener("resize", function () {
	if (isCarouselEligible()) {
		// initialize carousel, if not already
		if (!$(carouselTarget).hasClass("slick-initialized")) {
			loadCarousel();
		}
	} else {
		// destroy carousel, if initialized
		if ($(carouselTarget).hasClass("slick-initialized")) {
			$(carouselTarget).slick("unslick");
		}
	}
})

function isCarouselEligible() {
	var desktopStandardWidth = 987;
	var windowWidth = window.innerWidth ||
		document.documentElement.clientWidth ||
		document.body.clientWidth;
	return windowWidth <= desktopStandardWidth;
}