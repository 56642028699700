import React, { Component } from "react";
import { render, unmountComponentAtNode } from "react-dom";

import ModalFlowManagement from "./ModalFlowManagement";
import ConfirmationBanner from "./ConfirmationBanner";
import withModal from './withModal';

import SignIn from "../../components/sign-in/sign-in";
import user from "../../models/user";
import $ from "jquery";
import utilities from "../../support/utilities";

const Modal = ModalFlowManagement

var unmountModal = function(target) {
	unmountComponentAtNode(target)
	target.remove();
	var className = document.getElementsByTagName("body")[0].className;
	document.getElementsByTagName("body")[0].className = className.replace("fixed-body", "");
}

var renderModal = function(mode, fromPaywall) {
	let target = document.createElement('auth-modal');
	document.getElementsByTagName("body")[0].insertBefore(target, document.getElementsByTagName("body")[0].firstChild);
	if (target == undefined || target == null) {
	  console.error("Error: Failed to load auth modal component");
	} else {
		document.getElementsByTagName("body")[0].className = "fixed-body";
	  	var component = render(<Modal mode={mode != undefined ? mode: "sign-in"} onClose={()=>{unmountModal(target);$("[js-target='sign-in-register']").focus()}} fromPaywall={fromPaywall}/>, target);
	}
	if (fromPaywall == "true") {
		utilities.trigger("metrics:gbbPWSigninStart");
	} else {
		utilities.trigger("metrics:signin_start");
	}
	return SignIn.signInPromise;
}

var ensureSignedIn = function(param) {
	if (!user.isAuthenticated() && param?.followType === 'enable-this') {
		localStorage.setItem("follow-subscribe-data", JSON.stringify(param));
	}
	if(param === "save") {
		return user.isAuthenticated() ? $.Deferred().resolve() : renderModal("register");
	} else {
		return user.isAuthenticated() ? $.Deferred().resolve() : renderModal();
	}
}

var originalPosition = -1;

const monitorScroll = (event) => {
	if(originalPosition == -1) {
		originalPosition = window.scrollY;
	} else {
		let delta = Math.abs(originalPosition - window.scrollY)
		if(delta > 250) {
			let target = document.querySelector('confirmation-modal');
			unmountModal(target)
			window.removeEventListener('scroll', monitorScroll);
			originalPosition = -1;
			if ($("site-paywall").length > 0) {
				window.location.reload();
			}
		}
	}
}

$("body").on("register:success", () => {
	let target = document.createElement('confirmation-modal');
	document.getElementsByTagName("body")[0].insertBefore(target, document.getElementsByTagName("body")[0].firstChild);
	if (target == undefined || target == null) {
	  console.error("Error: Failed to load auth modal component");
	} else {
		document.getElementsByTagName("body")[0].className = "fixed-body";
	  	var component = render(
			<ConfirmationBanner emailAddress={user.getEmail()}/>
			, target
		);
		window.addEventListener('scroll', monitorScroll);
	}
});

export default {
	render: renderModal,
	ensureSignedIn: ensureSignedIn
}
