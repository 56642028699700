import React, {Component} from 'react';
import PasswordControl from "./PasswordControl.jsx"
import ReactHtmlParser from 'react-html-parser';
import SignIn from "../../components/sign-in/sign-in";
import user from "../../models/user";
import { trigger } from "../../support/utilities";
import common from "../../common";
import _indexBy from "lodash-amd/modern/collections/indexBy";
import CaptchaService, {CaptchaActions} from "../../services/captcha-service";
import $ from "jquery";
import utilities from "support/utilities";

const captchaTarget = "captchaTarget";

class SignInComponent extends Component {
	constructor() {
		super();
		// State holds form data
		this.state = {
			form: { email: "", password: "" },
			errors: [],
			isCaptchaEnabled: false,
		}
	}

	componentDidMount() {
		$("body").trigger("piano:signin-start");
		this.handleRecaptchaNotice();
	}

	handleChange(event) {
		// A field value needs to be updated in state
		this.setState({
			form: { ...this.state.form, [event.target.name]: event.target.value } // fieldName = fieldValue
		});
	}

	handleRecaptchaNotice = () => {
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					this.setState({isCaptchaEnabled: true});
				}
		});		
	}

	validateForm() {
		var errors = [];
		var errorMessage = "This field is required.";
		if (this.state.form.email.trim() === "") {
			errors.push({"field": "email", "errorMessage": errorMessage});
		} else {
			// Make sure the format is valid.
			if (!this.state.form.email.trim().match(common.EMAIL_REGEX)) {
					errors.push({"field": "email", "errorMessage": "This email address is not valid."});
			}
		}
		if (this.state.form.password === "") {
			errors.push({"field": "password", "errorMessage": errorMessage});
		}

		this.setState({
			errors: errors.length ? _indexBy(errors, "field") : []
		});
		return errors.length;
	}

	getError(fieldName) {
		var errorMessage = "";
		if (this.state.errors && this.state.errors[fieldName]) {
			errorMessage = this.state.errors[fieldName].errorMessage;
		}

		return errorMessage ?
				<span class="error-item">
					<span class="icon-alert"></span>
					<span class="error-message">
						{errorMessage}
					</span>
				</span> : "";
	}

	getBannerError(fieldName) {
		var errorMessage = "";
		if (this.state.errors && this.state.errors[fieldName]) {
			errorMessage = this.state.errors[fieldName].errorMessage;
		}

		return errorMessage ? <span class="error-message">{errorMessage}</span> : "";
	}

	signIn(captchaToken = null) {
		user.authenticate(this.state.form.email, this.state.form.password, captchaToken, "WEB_SITE")
			.then(function(user) { // TODO: if not user use response
				SignIn.signInPromise.resolve(user);
				trigger("metrics:signin_success", {user: user});

				// if there is a paywall in this page, reload the page
				if (document.getElementsByTagName("site-paywall").length > 0) {
					window.location.reload();
				}

				// Trigger event for unmount component
				this.props.onClose();
				$("body").trigger("piano:signin-success");
			}.bind(this)).catch(function(data) {
				this.handleLoginErrors(data);
			}.bind(this));
	};

	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		const errors = this.validateForm();
		if (errors > 0) {
			return;
		}
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					CaptchaService.executeRecaptcha(CaptchaActions.SIGN_IN_SUB).then((token) => {
						this.signIn(token);
					});
				} else {
					this.signIn();
				}
			});
	}

	handleLoginErrors(data) {
		var accountLockMsg = "Your account is blocked, please wait %s to unlock and try again. For immediate assistance, please contact <a href='/subscriber-help#contact-customer-service'>Customer Service</a>."
		var triesLeftMsg = ". You have %s tries left.";
		var errorMessage = "You have entered an invalid email and password combination. Please try again";
		var extendedErrorMessage = "";

		if (data.responseJSON.hasOwnProperty(common.LOGIN_TRIES_LEFT)) {
			triesLeftMsg = triesLeftMsg.replace("%s", data.responseJSON.tries_left);
			extendedErrorMessage = ReactHtmlParser(triesLeftMsg);
		} else if (data.responseJSON.hasOwnProperty(common.ACCOUNT_LOCKOUT_DURATION)){
			accountLockMsg = accountLockMsg.replace("%s", utilities.getUnlockAccountTime(data.responseJSON.lockout_duration));
			errorMessage = ReactHtmlParser(accountLockMsg);
		} else if (data && data.responseJSON && data.responseJSON.code == 401) {
			extendedErrorMessage = (<span> or <a onClick={() => this.props.navigateToForgotPassword()}>reset your password.</a></span>);
		} else {
			errorTemplate = (
					<div className="error-item-banner">
						<span className="icon-alert icon-alert-banner"></span>
						<div class="error-message-banner">
							{ReactHtmlParser(common.ERROR_MESSAGE)}
						</div>
					</div>
			);
		}

		if (!errorTemplate) {
			var errorTemplate = (
				<div class="error-item-banner">
					<span class="icon-alert icon-alert-banner"></span>
					<div class="error-message-banner">
						{errorMessage}{extendedErrorMessage}
						<br/><br/>
						Don't have an account? <a onClick={() => this.props.toggle()}>Create one.</a>
					</div>
				</div>
			);	
		}

		var error = {"common": {"field": "common", "errorMessage": errorTemplate}};
		this.setState({
			errors: error
		});
		$("body").trigger("piano:signin-failure");
	}

	isNoFieldError(fieldName) {
		return !((fieldName in this.state.errors) || ("common" in this.state.errors));
	}

	render() {
		return (
			<div class="piano-card">
				<div class="header header-signin-reg">
					<span class="header-close-btn" onClick={() => this.props.onClose() }>
						<i className="hbr-icon icon-times icon-signin-reg"></i>
					</span>
					<h1 class="header-title title-signin-reg">{this.props.isGiftOffer ? "" : this.props.title}</h1>
					<spah class="header-subtitle subtitle-signin-reg">{this.props.isGiftOffer ? "" : this.props.subtitle}</spah>
				</div>
				<div class="body">
					<div className="wrapper">
						<h2 class="body-header">Sign in to your account</h2>
						{this.props.isGiftOffer ? <p class="body-description">To send a gift, you'll need  to sign into your HBR.org account.</p> : ""}
						<hr className="body-divider"/>
						<p class="alternate">Don't have an account? <a onClick={() => this.props.toggle()}>Create one</a></p>
						{this.getBannerError("common")}
						<form class="form" onSubmit={this.handleSubmit.bind(this)}>
							<div class="input-group">
								<span class={this.isNoFieldError("email") ? "input-group-label" : "input-group-label label-error"}>Email</span>
								<input type="text" name="email"
											 class={this.isNoFieldError("email") ? "text-input" : "text-input input-error"}
											 value={this.state.form.email}
											 onChange={this.handleChange.bind(this)}/>
								{this.getError("email")}
							</div>
							<div class="input-group">
								<span class={this.isNoFieldError("password") ? "input-group-label" : "input-group-label label-error"}>Password</span>
								<PasswordControl password={this.state.form.password} error={!this.isNoFieldError("password")} handleChange={this.handleChange.bind(this)} />
								{this.getError("password")}
							</div>
							<div className="input-group">
								<div id={captchaTarget} style={{marginBottom: "12px"}}></div>
								{this.getError("captcha")}
							</div>
							<div class="input-group">
								<a class="password-reset" onClick={() => this.props.navigateToForgotPassword()}>Forgot password?</a>
							</div>
							<div class="input-group clear">
								<a className="btn-back piano-signin-back-btn" onClick={() => this.props.navigateToGoBack()}><i className="icon icon-caret-left"></i> Go back</a>
								<input class="f-r" type="submit" value={this.props.isGiftOffer ? "On to recipient >" : "On to checkout"}/>
							</div>
						</form>
						{this.state.isCaptchaEnabled && <div class="recaptcha-privacy-notice">
							This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default SignInComponent;
