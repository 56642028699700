
import $ from "jquery";
import _bind from "lodash-amd/modern/functions/bind";
import interactionService from "../../../services/hbr/interaction";
import subscriptionService from "../../../services/subscription-utils";
import urlLengthService from "../../../services/url-length-utils";
import validatorService from "../../../services/validator-utils";
import socialNetworkSharing from "../../social-network-sharing/social-network-sharing";

var templates = {
	base: require("./templates/base"),
	email: require("./templates/email"),
	social: require("./templates/social")
};

var shareContent = {};
var currentStreamItem = null;

var shareNetworks = {
	email: {
		name: "email",
		iconName: null,
		template: templates.email
	},
	facebook: {
		name: "Facebook",
		iconName: "facebook",
		template: templates.social
	},
	twitter: {
		name: "Twitter",
		iconName: "twitter",
		template: templates.social
	},
	linkedin: {
		name: "LinkedIn",
		iconName: "linkedin",
		template: templates.social
	}
};

var options = {
	shareContentTarget: "[js-target='flyout-share-content']",
	shareNavTarget: "[js-target='share-flyout-nav']",
	shareNavItemTarget: "[js-target='share-flyout-nav-item']",
	shareConnectTarget: "[js-target='share-connect']",
	sharePostTarget: "[js-target='share-post']",
	shareMessageTarget: "[js-target='share-message']",
	shareToTarget: "[js-target='share-to']",
	shareFromTarget: "[js-target='share-from']",
	shareUrlTarget: "[js-target='share-url']",
	defaultShareNetwork: "facebook",
	allowMultiple: false,
	requiresSignIn: false,
	focusFirstInput: false,
};

var currentShareNetwork = options.defaultShareNetwork;

var navItemClickHandler = function (e) {
	e.preventDefault();
	var $navItem = $(e.target).parents(options.shareNavItemTarget);
	if ($navItem.length === 1) {
		$(options.shareNavTarget).find(".active").removeClass("active");
		$(options.shareNavTarget).find("[role='tab']").attr("aria-selected", false);
		$navItem.addClass("active");
		$navItem.find("[role='tab']").attr("aria-selected", true);
		currentShareNetwork = $navItem.data("share-network");
		liveRender();
	}
};

var sharePostClickHandler = function (e) {
	e.stopImmediatePropagation();
	e.preventDefault();

	var isError = false;
	shareContent.url = urlLengthService.setRelativePath(shareContent.url);
	shareContent.description = $(options.shareContentTarget).find(options.shareMessageTarget).val();
	shareContent.provider = currentShareNetwork;
	if (currentShareNetwork === "email") {
		// get extra data from template: to, from
		var shareToTarget = $(options.shareContentTarget).find(options.shareToTarget);
		var shareFromTarget = $(options.shareContentTarget).find(options.shareFromTarget);
		shareContent.sharedWith = [];
		shareContent.sharedWith = ($(shareToTarget).val().split(","));
		shareContent.from = $(shareFromTarget).val();
		// validate the to and from field
		validatorService.removeError($(shareToTarget));
		if ($(shareFromTarget).length > 0 && !validatorService.validateElement($(shareFromTarget), "email")) {
			isError = true;
		}
		if (!validatorService.validateMulitpleElements($(shareToTarget), "email")) {
			isError = true;
		}
		if (isError) {
			return false;
		}
	}
	// TODO I am removing the name value because it was causing issues
	// must investigate whether this is not necessary for all shares or
	// just this process
	delete shareContent.name;
	interactionService.request("shared", shareContent);
	var confirmationMessage = {};
	if (currentShareNetwork === "twitter" || currentShareNetwork === "email") {
		confirmationMessage.message = "Shared via " + shareNetworks[currentShareNetwork].name;
	}
	$(this).trigger("destroy", confirmationMessage);
	// Update digital data
	var eventData = {};
	eventData.shareMedium = shareNetworks[currentShareNetwork].name;
	eventData.eventAction = "Content Share - " + eventData.shareMedium;
	eventData.element = currentStreamItem;
	$("body").trigger("content-action:success", eventData);
};

function getShareNetworkTemplateData (loggedIn) {
	return {
		shareNetwork: currentShareNetwork,
		shareNetworkLoggedIn: loggedIn,
		shareNetworkIcon: shareNetworks[currentShareNetwork].iconName,
		shareNetworkDisplayName: shareNetworks[currentShareNetwork].name,
		shareNetworkAvatarPath: options.defaultShareNetworkAvatarPath
	};
}

function renderShareNetworkTemplate () {
	// If there isn't an active share flyout, reset shareNetwork
	/*
	if ($(options.shareContentTarget).length === 0) {
		currentShareNetwork = options.defaultShareNetwork;
	}*/
	var dfd = $.Deferred();
	try {
		dfd.resolve(shareNetworks[currentShareNetwork].template(getShareNetworkTemplateData(true)));
	} catch (e) {
		dfd.reject(null);
	}
	return dfd.promise();
}

function liveRender () {
	renderShareNetworkTemplate().then(function(renderedSubTemplate) {
		$(options.shareContentTarget).html(renderedSubTemplate);

		// Trigger event on social network sharing render
		$.event.trigger({
			type: "socialNetworkSharingRender"
		});
	});
}

/**
 * render
 * @param {Object} data
 * @returns {*} HTML string
 */
exports.render = function (data) {
	if (data.network) {
		// the network was specified during the flyout render
		// set this as the
		currentShareNetwork = data.network;
	}

	if (!shareNetworks[currentShareNetwork]) {
		throw "Unsupported share network!";
	}

	// save the data as the shareContent
	// shareContent is immutable after this point so setting
	// it here ensures that the flyout has the latest data before
	// rendering.
	shareContent = data;

	return renderShareNetworkTemplate().then(function(renderedSubTemplate) {
		return templates.base({
			shareTitle: data.title,
			shareUrl: data.url,
			subTemplate: renderedSubTemplate,
			setFullPath: urlLengthService.setFullPath,
			network: currentShareNetwork
		});
	});
};

/**
 * getData
 * Gets necessary data from the DOM for rendering share template and
 * hitting share/history end points
 * @param {Object} $element
 * @returns {Object}
 */
exports.getData = function ($element) {
	var $streamItem = $element.parents("stream-item");
	currentStreamItem = $streamItem;
	var selectedNetwork = $element.data("share-network");
	if (!selectedNetwork) {
		selectedNetwork = options.defaultShareNetwork;
	}
	return {
		id: $streamItem.attr("data-id"),
		title: $streamItem.data("title"),
		url: subscriptionService.processUrl($streamItem.attr("data-url")),
		topic: $streamItem.data("topic"),
		authors: $streamItem.data("authors"),
		contentType: $streamItem.data("content-type"),
		image: $streamItem.data("content-image"),
		summary: $streamItem.data("summary"),
		activityType: "shared",
		network: selectedNetwork
	};
};

/**
 * bindInteractions
 * Binds interactions on template items
 */
exports.bindInteractions = function () {
	socialNetworkSharing.attachEventHandlers();
	$(options.shareContentTarget).parent()
		.on("click", options.shareNavItemTarget, _bind(navItemClickHandler, this))
		.on("click", options.sharePostTarget, _bind(sharePostClickHandler, this));
};

/**
 * getOverrideOptions
 * Returns a list of options specific to the share flyout
 * @returns {Object}
 */
exports.getOverrideOptions = function () {
	return options;
};

/**
 * Initialize new Social Network Sharing component
 */
socialNetworkSharing.initialize();
