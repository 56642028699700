
import $ from "jquery";
import mixinComponent from "../../mixins/component";
import auth from "../../services/auth";
import state from "../../services/state";
import SignInModal from "../../react/AuthModal/Modal";

import "outside-events";

let FLYOUT_NODE;

var flyoutTypes = {
	save: require("./save/save"),
	organize: require("./organize/organize"),
	share: require("./share/share"),
	discard: require("./discard/discard"),
	"share-list": require("./share-list/share-list"),
	"bulk-discard": require("./bulk-discard/bulk-discard"),
	"bulk-organize": require("./bulk-organize/bulk-organize"),
	"change-password": require("./change-password/change-password"),
	"forgot-password": require("./forgot-password/forgot-password"),
	notification: require("./notification/notification"),
	embed: require("./embed/embed")
};

// Flyouts that we want to center in the viewport rather than above/below where the click event was.
const centeredFlyouts = [
	"save-flyout", 
	"notification-flyout", 
	"share-flyout", 
	"organize-flyout", 
	"bulk-organize-flyout",
	"share-list-flyout"
];

const fixedCenteredFlyouts = {
	flyouts: [
		"share-flyout", 
		"share-list-flyout"],
	centeredIDPFlyouts: [   
		"Order for your team and save!",
		"Format Details",
		"Quantity Pricing Details"
	]
};

var templates = {
	base: require("./templates/base"),
	signInPrompt: require("./templates/sign-in-prompt"),
	confirmation: require("./templates/confirmation"),
	"forgot-password": require("./forgot-password/template")
};

var pointerPositions = {
	topLeft: "pointer-top-left", // default style, class may not exist
	topCenter: "pointer-top-center",
	topRight: "pointer-top-right",
	bottomLeft: "pointer-bottom-left",
	bottomCenter: "pointer-bottom-center",
	bottomRight: "pointer-bottom-right"
};

const flyoutDefaults = {
	pointerClass: pointerPositions.topLeft,
	containerTemplate: templates.base,
	containerContentTarget: "[js-target='flyout-content']",
	requiresSignIn: true,
	signInPromptTarget: "[js-target='show-sign-in']",
	containerClasses: ["flyout", "clearfix"],
	containerTarget: "flyout-container",
	closeButtonTarget: "hide-flyout",
	allowMultiple: false,
	confirmationTimeout: 2000, // show confirmations for two seconds
	focusFirstInput: true,
};

export default function Flyout($el) {
	this.initializeAsComponent($el);
}

Flyout.prototype = {
	globalEvents: {
		"click [js-target$='flyout'],[data-js-target$='flyout']": "show"
	},

	/**
	 * Extends default options with override options for a specific flyout type
	 * @param {Object} flyout - The flyout to get override options for
	 * @returns {Object} The default flyout options with overrides applied
	 */
	getOptions: function getFlyoutOptions(flyout) {
		let overrideOptions = {};

		if ($.isFunction(flyout.getOverrideOptions)) {
			overrideOptions = flyout.getOverrideOptions();
		}

		return $.extend({}, flyoutDefaults, overrideOptions);
	},

	show: function(ev) {
		ev.preventDefault();
		ev.stopImmediatePropagation();

		// Because events are globally bound,
		// We need to manually set this.$el
		this.$el = $(ev.currentTarget);

		var self = this;

		// Extract the type.
		var target = this.$el.attr("js-target") || this.$el.data("js-target");

		var type = target ? target.split("-flyout")[0] : undefined;

		// Generate the selectors used to render the flyout.
		var flyout = flyoutTypes[type] || {};

		if (this.$el.attr("data-flyout-replace") &&
			this.$el.attr("data-flyout-replace") === type) {
			this.replaceFlyoutContent(flyout, templates[type]());
			return;
		}

		// Cache offset information.
		var offset = this.$el.offset();

		// Add the entire element's height to the offset.
		offset.top += this.$el.height();

		// Find the data necessary for the flyout.
		var flyoutData = $.isFunction(flyout.getData) ? flyout.getData(this.$el) : {};

		var render = this.renderFlyoutFragment(offset, flyoutData, flyout, target);

		render.then(function (docFragment) {
			$("body").append(docFragment);
			// The newly created fragment.
			var $el = $("[js-target='flyout-container']");
			FLYOUT_NODE = $el[0];

			let modulePos;
			if(type=="save")
			{
				($el.find("[js-target='hide-flyout']")).children().attr("title","Cancel Save");
			}
			else if(type=="share")
			{
				($el.find("[js-target='hide-flyout']")).children().attr("title","Cancel Share");
			}
			else if(type=="change-password")
			{
				($el.find("[js-target='hide-flyout']")).children().attr("title","change password dialog close");
			}
			// Checking here for whether or not we should center this flyout and then call we can call the respective positioning function
			if(centeredFlyouts.indexOf(target) > -1) {
				// Checking for whether we need to render the flyout as fixed or not (share and specific IDP notification flyouts)
				if (fixedCenteredFlyouts.flyouts.indexOf(target) > -1 ||  flyoutData !== undefined && fixedCenteredFlyouts.centeredIDPFlyouts.indexOf(flyoutData.title) > -1) {
					// If we're rendering the share/share-list/IDP flyout, we can set positioning to fixed and allow
					// it to scroll with the screen as the height will always be the same for this type of flyout.
					modulePos = state.centerModulePosition($el.outerHeight(true), $el.outerWidth(true), false);

					// Apply fixed values to center flyout
					$el.css({
						top: `${modulePos.top}px`,
						left: `${modulePos.left}px`,
						position: "fixed"
					});     
				}else{
					// If it doesn't need to be fixed then we can just position it based on the document height and scroll values
					modulePos = state.centerModulePosition($el.outerHeight(true), $el.outerWidth(true), true);
				} 
			}else{
				// If it doesn't need to be centered, use getModulePosition() to see if we can render above/below where the user clicked
				modulePos = state.getModulePosition(self.$el.offset().top, self.$el.offset().left, $el.outerHeight(true), $el.outerWidth(true));
			}
			

			if (modulePos.adjustPos) {
				$el.css("top", modulePos.top || offset.top)
					.css("left", modulePos.left || "")
					.css("right", modulePos.right || "")
					.removeClass(function (index, className) {
						className.replace(/(^|\s)pointer-\S+/g, "");
					})
					.addClass(modulePos.className);
			}

			var navItems = $el.find("[js-target='share-flyout-nav-item']");
			var sharing = $el.find("[js-target='flyout-share-content']");
			if (sharing.length > 0 && navItems.length > 0) {
				var shNetwork = $(sharing.children()[0]).attr("js-target").split("-")[1];
				$.each(navItems, function(i, item) {
					$(item).removeClass("active");
				});
				$.each(navItems, function(i, item) {
					if (shNetwork === item.dataset.shareNetwork) {
						$(item).addClass("active");
					}
				});
			}

			if (modulePos.move > 0) {
				$("body, html").animate({
					scrollTop: $(window).scrollTop() + modulePos.move
				}, "500");
			}

			$el.show();

			const options = self.getOptions(flyout);

			if (options.focusFirstInput) {
				$el.find("input:first").trigger("focus");
			}

			if ($.isFunction(flyout.bindInteractions)) {
				flyout.bindInteractions($el);
			}

			// trap focus in the flyout popup
			trapFocusinLoop(FLYOUT_NODE);

			// allow check/uncheck checkboxes in the flyout popup
			addInteractionToCheckboxes();
		});

	},

	/**
	 * validateFlyoutType
	 * Ensures a flyout type exists and exports all necessary methods
	 * @param {String} type
	 */
	validateFlyoutType: function(type) {
		if (!flyoutTypes[type]) {
			throw new Error("Cannot instantiate flyout of type: " + type);
		}

		if (!$.isFunction(flyoutTypes[type].render)) {
			throw new Error("Flyout type " + type + "does not export a render function");
		}
	},

	/**
	 * replaceFlyoutContent
	 * Empty the flyout container currently displayed and fill it with another flyout's template contents
	 * @param {Object} flyout - flyout object
	 * @param {Object} tmplt - template object
	 */
	replaceFlyoutContent: function (flyout, tmplt) {
		var elt = $("[js-target='flyout-container']");
		elt.empty();
		$(elt).append(templates.base);
		$(elt).append(tmplt);

		const options = this.getOptions(flyout);

		if (options.focusFirstInput) {
			elt.find("input:first").trigger("focus");
		}

		elt.find("[js-target='" + flyoutDefaults.closeButtonTarget + "']")
			.on("click", function (e) {
				e.preventDefault();
				$(elt).remove();
			});

		if ($.isFunction(flyout.bindInteractions)) {
			flyout.bindInteractions(elt);
		}

		$(flyout).on("destroy",
			function (e, confrm) {
				if (confrm && confrm.message) {
					elt.empty();
					$(elt).append(templates.base);
					$(elt).append(templates.confirmation(confrm));
					setTimeout(function () { $(elt).remove(); }, flyoutDefaults.confirmationTimeout);
				}
				else {
					$(elt).remove();
				}
			});
	},

	/**
	 * renderFlyoutFragment
	 * Create a flyout container and fill it using the type's render method
	 * @param {Object} position - object which provides top and left properties
	 * @param {Object} flyoutData - Data object provided to the flyout.
	 * @param {Object} flyout - The flyout to render.
	 * @param {string} target - The type of flyout
	 * @returns {DocumentFragment}
	 */
	renderFlyoutFragment: function(position, flyoutData, flyout, target) {
		var containerOverlay;

		const options = this.getOptions(flyout);

		var flyoutContainerTarget = "[js-target='" + options.containerTarget + "']";
		var flyoutCloseTarget = "[js-target='" + options.closeButtonTarget + "']";

		if (!options.allowMultiple && $(flyoutContainerTarget).length > 0) {
			$(flyoutContainerTarget).remove(); // kill existing
		}

		var flyoutRenderDeferred = $.Deferred();
		var flyoutDocFragment = document.createDocumentFragment();
		var container = document.createElement("div");
		container.className = options.containerClasses.join(" ");
		$(container).addClass(options.pointerClass);
		container.setAttribute("js-target", options.containerTarget);
		container.setAttribute("role", "dialog");
		container.setAttribute("aria-labelledby","dialog-title")

		if (target || flyoutData) {
			// Checking here for centered flyouts and IDP flyouts as we'll need a transparent overlay behind these types of flyout.
			if(centeredFlyouts.indexOf(target) > -1 || flyoutData !== undefined && fixedCenteredFlyouts.centeredIDPFlyouts.indexOf(flyoutData.title) > -1) {
				// Remove the pointer as we'll be centering in the middle of the viewport
				$(container).addClass("pointer-none");

				// Add specific styles for IDP flyouts
				if(flyoutData !== undefined && fixedCenteredFlyouts.centeredIDPFlyouts.indexOf(flyoutData.title) > -1)  {
					$(container).addClass("flyout-container--new")
				}

				// Add a background transparent overlay
				containerOverlay = document.createElement("div");
				containerOverlay.className = "modal-overlay flex-row align-items-center justify-center";
				document.body.appendChild(containerOverlay);

			}
		}

		container.innerHTML = options.containerTemplate();
		flyoutDocFragment.appendChild(container);

		const appendFlyoutFragment = function appendFlyoutFragment() {
			flyout.render(flyoutData).then(function (containerContent) {
				$(container).append(containerContent);
				flyoutRenderDeferred.resolve(flyoutDocFragment);
			});
		};

		if (options.requiresSignIn && containerOverlay) {
			const pieLoaderContainer = document.createElement("div");
			pieLoaderContainer.className = "loading";

			const pieRotationElement = document.createElement("div");
			pieRotationElement.className = "pie rotation";

			const pieFillerElement = document.createElement("div");
			pieFillerElement.className = "pie filler";

			const pieMaskElement = document.createElement("div");
			pieMaskElement.className = "mask";

			pieLoaderContainer.appendChild(pieRotationElement);
			pieLoaderContainer.appendChild(pieFillerElement);
			pieLoaderContainer.appendChild(pieMaskElement);

			containerOverlay.appendChild(pieLoaderContainer);

			auth.getUser()
				.then(() => { appendFlyoutFragment(); }, () => { SignInModal.render(); })
				.always(() => { pieLoaderContainer.remove(); });

		} else {
			appendFlyoutFragment();
		}

		let timeout;
		function showConfirmation(confirmation) {
			container.innerHTML = options.containerTemplate();
			$(container).append(templates.confirmation(confirmation));

			// We'll need to reinitialise the click handler here as we've replaced the template.
			// The DOM object the original click handler was on is now gone.
			$(flyoutCloseTarget).on("click", (e) => {
				e.preventDefault();
				if(typeof timeout !== "undefined") {
					clearTimeout(timeout);
				}
				destroy();
			});
		}

		function destroy (e, confirmation) {
			if (confirmation && confirmation.message) {
				showConfirmation(confirmation);
				timeout = setTimeout(destroy, options.confirmationTimeout);
				return;
			}
			$(flyoutContainerTarget).remove();
			if (containerOverlay) {
				$(containerOverlay).remove();
			}
		}

		// Bind to close target, and destroy event
		$(flyout).one("destroy", destroy);
		$(container).one("clickoutside", destroy);
		$(container).find(flyoutCloseTarget).on("click", function(e) {
			e.preventDefault();
			destroy();
		});

		return flyoutRenderDeferred.promise();
	}
};


let focusableContent;  // array of focusable nodes.

/**
 * getFocusableContent
 * Retreives an array of elements that will be focusable inside a given node.
 * @param {DOM node} targetElement - target DOM node we want to find focusable elements from.
 * @returns {Array} - focusable Content
 */
const getFocusableContent = function(targetElement) {
	const elementsToBeFocusable = "button, [href] > i, input, select, textarea, [tabindex]:not([tabindex='-1'])";

	return targetElement.querySelectorAll(elementsToBeFocusable);
};

/**
 * updateFocusableContent
 * Updates the focusable content inside a given node.
 * @param {DOM node} targetElement - target DOM node in which the focusable Content will be updated
 */
export const updateFocusableContent = function(targetElement = FLYOUT_NODE) {
	focusableContent = getFocusableContent(targetElement);
};

/**
 * trapFocusinLoop
 * Traps focus in a given node for keyboard navigation, and allows to return to the first element when the focus reaches the last one.
 * @param {DOM node} targetElement - target DOM node.
 */
const trapFocusinLoop = function(flyoutElement) {			  
	updateFocusableContent(flyoutElement);
	const firstFocusableElement = focusableContent[0];
	const lastFocusableElement = focusableContent[focusableContent.length - 1];
	firstFocusableElement.focus();

	// enable interaction with Enter key, and disable SpaceBar from scrolling
	const iconLinks = flyoutElement.querySelectorAll("[href] > i");
	addEnterKeyInteraction(iconLinks)

	// prevent losing focus when "Save" button is disabled and Tab is pressed
	const formInputElements = flyoutElement.querySelector(".field-container").querySelectorAll("input, textarea");
	var textArea = formInputElements[1];
	textArea.addEventListener("keydown", function (e) {
		if (e.key === "Tab" || e.keyCode === 9) {
			var input = formInputElements[0];
			if (input.value.length === 0) {
				firstFocusableElement.focus()
				e.preventDefault();
			}
		}
	});
	
	flyoutElement.addEventListener("keydown", function (e) {
		let isTabPressed = e.key === "Tab" || e.keyCode === 9;

		if (!isTabPressed) return;

		if (e.shiftKey) {   // if shift + tab combination is pressed
			if (document.activeElement === firstFocusableElement) { // if focused has reached to first focusable element add focus for the last focusable element
				lastFocusableElement.focus();
				e.preventDefault();
			}
		} else { // if tab key is pressed and focus has reached last focusable element then focus first focusable element after pressing tab
			if (document.activeElement === lastFocusableElement) {
				firstFocusableElement.focus();
				e.preventDefault();
			}
		}
	});
};

/**
 * addInteractionToCheckboxes
 * Allows to check/uncheck checkboxes(styled as hidden) in the flyout popup when pressing the Space key.
 */
const addInteractionToCheckboxes = function () {
	var labels, checkboxes;
	labels = null;
	checkboxes = null;

	labels = document.querySelectorAll(".flyout-list>li>.hbr-checkbox>label");
	checkboxes = document.querySelectorAll(".flyout-list>li>.hbr-checkbox>input");

	labels.forEach(function (label) {
		label.addEventListener("keypress", function (e) {
			e.preventDefault()
			
			const isSpaceBarPressed = e.key === "Spacebar" || e.keyCode === 32;
			if (!isSpaceBarPressed) return;

			const labelSelectedIndex = Array.from(labels).findIndex(function(arrayLabel) {
				return arrayLabel.getAttribute("for") === label.getAttribute("for")
			});

			if (labelSelectedIndex != 0 && labelSelectedIndex != labels.length - 1) {
				checkboxes[labelSelectedIndex].checked = !checkboxes[labelSelectedIndex].checked;
			}

			if (label.getAttribute("for") === "new-list") {  // if "Create New Folder" checkbox is toggled, set focus on "Save" button
				label.click();
				FLYOUT_NODE.querySelector("button").focus();
			}
		});
	});
};

/**
 * Enables to interact with Enter key, and disables scrolling for SpaceBar key
 * @param {DOM nodes} elements - array of DOM nodes  
 */
function addEnterKeyInteraction (elements) {
	elements.forEach(function (element, index) {
		element.addEventListener("keydown", function (e) {
			if (e.key === "Spacebar" || e.keyCode === 32 || e.key === "Enter" || e.keyCode === 13) {
				e.preventDefault();
				element.click();
			}

			if (index === 1) { // if "Create New Folder" icon is selected to open the form, set focus on the first input field
				FLYOUT_NODE.querySelector("#list-form-name").focus();
			}
		});
	});
	
}


mixinComponent(Flyout, "body");
