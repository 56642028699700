import $ from "jquery";
import interaction from "../../services/hbr/interaction";
import user from "../../models/user";
import SignIn from "components/sign-in/sign-in";
import SignInModal from "../../react/AuthModal/Modal";
import mixinComponent from "../../mixins/component";
import state from "services/state";
import auth from "../../services/auth";
import _map from "lodash-amd/modern/collections/map";
import _contains from "lodash-amd/modern/collections/contains";
import "support/digital-data/handler/follow-subscribe.js";
const trigger = require("support/utilities").trigger;
import modalBox from "../modal-box/modal-box";
import common from "../../common"

(function () {
	const followSubData = "follow-subscribe-data";
	auth.getUser().then(() => {
		if (localStorage.getItem(followSubData)) {
			const contentObject = JSON.parse(localStorage.getItem(followSubData));
			contentObject.type = contentObject.activity;
			delete contentObject.activity;
			$("body").trigger("follow:success", contentObject);
		}
		localStorage.removeItem(followSubData);
	});
}());
export default function FollowSubscribe($el) {
	this.initializeAsComponent($el);
}

FollowSubscribe.prototype = {

	successHandler: null,
	failureHandler: null,	
	beforeSendHandler: null,

	events: {
		"click [js-target='enable-this']": "enable",
		"click [js-target='disable-all']": "disableAll",
		"click [js-target='enable-this-by-email']": "enableByEmail",
		"click [js-target='disable-this']": "disable",
		"click [js-target='sign-in-this']": "renderSignInModal",
		"click [js-target='follow-subscribe-to-mb']": "subscribeWithPublicProfile",
		"click [js-target='subscribe-for-newsletter-tout']": "subscribeForNewsletterTout",
		"click [js-target='subscriber-only']": "renderError"
	},

	/**
	 * Makes a request to the interaction service.
	 *
	 * @private
	 * @param {object} evt - The forwarded jQuery event object.
	 * @param {string} method - The request method to use.
	 */
	interactionRequest: function (evt, method) {
		let isSubscribersOnly = false;
		const followSubscribeEl = evt.target.closest(`[data-name="${this.dataset.name}"]`);
		if (followSubscribeEl) {
			// Check if 'follow-topic-subscriber' exists in the class list
			isSubscribersOnly = followSubscribeEl.classList.contains("follow-topic-subscriber");
		}
		// Build the content object from the data attributes.
		let component = this;
		let contentObject = $.extend({}, this.dataset);

		// Normalize the activity to the type.
		contentObject.type = contentObject.activity;
		delete contentObject.activity;
		delete contentObject.id;

		// Remove the component attachment.
		delete contentObject.component;

		// If request method is post then adding disabled class else removing it.
		if (method === "post") {
			component.$el.addClass("disabled");
			// update simiar components
			$("body").trigger("follow:update", {element: component.$el, disabled: 1, type: contentObject.type});
		} else {
			component.$el.removeClass("disabled");
			// update simiar components
			$("body").trigger("follow:update", {element: component.$el, disabled: 0, type: contentObject.type});
		}

		// show loading indicator for unsubscribe all feature
		if (method === "deleteAll") {
			modalBox.activate($("body")).render();
		}

		if(isSubscribersOnly && state.current !== "registered subscribers") {
			return false;
		};
		
		// Transmit this interaction.
		interaction.request(contentObject.type, method, contentObject)
			.then(function () {
				if (state.current.indexOf("guest") !== 0) {
					user.fetch();
					if (this.$el.data("activity") === "topic") {
						$(document).trigger("topics:change");
					}
				}

				if (this.successHandler) {
					this.successHandler(evt);
				}

				if (method === "post") {
					$("body").trigger("follow:success", contentObject);
				}

				if (method === "delete") {
					$("body").trigger("unfollow:success", contentObject);
				}

				if (method === "deleteAll") {
					modalBox.close();
					$("[js-target='newsletter-unsubscribe-confirmation']").hide();
					$("[js-target='newsletter-unsubscribe-success']").show();
					$("[js-target='unsubscribe-success-msg']").show();
				}
			}.bind(this)

			).fail(function () {
				if (this.failureHandler) {
					this.failureHandler("unknown error", evt);
				}
				if (method === "deleteAll") {
					modalBox.close();
					$("[js-target='newsletter-unsubscribe-confirmation']").hide();
					$("[js-target='newsletter-unsubscribe-success']").show();
					$("[js-target='unsubscribe-error-msg']").html(common.ERROR_MESSAGE).show();
				}
			}.bind(this));
	},

	enable: function (evt) {
		evt.stopPropagation();
		evt.stopImmediatePropagation();
		evt.preventDefault();

		return this.ensureAuthenticated(evt, "post");
	},

	enableByEmail: function (evt) {
		evt.stopPropagation();
		evt.stopImmediatePropagation();
		evt.preventDefault();
		return this.signUpByEmail(evt, "post");
	},

	disable: function (evt) {
		evt.stopPropagation();
		evt.stopImmediatePropagation();
		evt.preventDefault();

		return this.ensureAuthenticated(evt, "delete");
	},

	disableAll: function (evt) {
		evt.stopPropagation();
		evt.stopImmediatePropagation();
		evt.preventDefault();

		return this.ensureAuthenticated(evt, "deleteAll");
	},

	/**
	 * Ensure the user is authenticated, otherwise open the flyout - unless we're using an email field.
	 *
	 * @private
	 * @param {object} evt - A jQuery event object.
	 * @param {string} method - The request method to use.
	 */
	ensureAuthenticated: function (evt, method) {
		const match = evt.handleObj.selector.match(/js-target='([^']+)'/);
		const followType = match ? match[1] : null;
		// eslint-disable-next-line no-unused-vars
		const {component, ...rest} = this.dataset;
		SignInModal.ensureSignedIn({
			followType,
			...rest
		}).then(function () {
			this.interactionRequest(evt, method);
		}.bind(this));
	},

	signUpByEmail: function (evt, method) {
		if (this.dataset.email && this.dataset.email.length > 2 &&
				this.dataset.email.indexOf("@") !== -1) {
			this.interactionRequest(evt, method);
			trigger("metrics:dcs_email_update_signup", {email: this.dataset.email});
		} else if (state.current.indexOf("guest") !== 0) {
			this.interactionRequest(evt, method);
		} else {
			if (this.failureHandler) {
				this.failureHandler("invalid email", evt);
			}
		}
	},

	renderSignInModal: function (ev) {
		ev.preventDefault();
		return this.ensureAuthenticated(ev, "post");
	},

	subscribeWithPublicProfile: function(evt) {			
		evt.stopPropagation();
		evt.stopImmediatePropagation();
		evt.preventDefault();

		if (this.beforeSendHandler &&
		 this.beforeSendHandler(this,evt)) {
			this.interactionRequest(evt, "post");
		}
	},

	subscribeForNewsletterTout: function (evt) {
		evt.stopPropagation();
		evt.stopImmediatePropagation();
		evt.preventDefault();
		this.$el.addClass("sign-up-success");
		let contentObject = $.extend({}, this.dataset);
		$("body").trigger("follow:success", contentObject);
		return this.ensureAuthenticated(evt, "post");
	},

	renderError: function(evt) {
		evt.preventDefault();
		this.$el.closest(".newsletter-item-container").find(".error-subscriber-only").show();
		this.$el.closest(".newsletter-tile-item").find(".error-subscriber-only").show();
	}
};

/**
 * Update all existing instances with the server-sent state.
 *
 * @param {array} list - The list of server-sent state items.
 */
FollowSubscribe.updateAll = function (list) {
	let ulist = _map(list, function (s) {
		return s.toUpperCase();
	});
	$("follow-subscribe").filter(function () {
		return _contains(ulist, $(this).data("name").toUpperCase());
	}).addClass("disabled");
};

FollowSubscribe.updateFollowing = function (list) {
	let topics = _map(list, function (s) {
		return s.toUpperCase();
	});
	$("follow-subscribe[data-activity='topic']").filter(function () {
		return _contains(topics, ($(this).data("name").toUpperCase()));
	}).addClass("disabled");
};

FollowSubscribe.updateSubscriptions = function (list) {
	let newsletters = _map(list, function (newsletter) {
		if(newsletter) {
			return newsletter.toUpperCase();
		}
	});
	let newsletterTags = $("follow-subscribe[data-activity='newsletter']");
	let previewPage = location.href.indexOf("//preview.") > -1;

	newsletterTags.filter(function () {
		if($(this).data("name")) {
			return _contains(newsletters, ($(this).data("name").toUpperCase()));
		}
	}).addClass("disabled");
	newsletterTags.filter(function () {
		if($(this).data("name")) {
			return previewPage;
		}
	}).removeClass("do-not-show").addClass("preview");

};

FollowSubscribe.updateMarketingCampaignSubscriptions = function (list) {
	let marketingCampaigns = _map(list, function (marketingCampaign) {
		if(marketingCampaign) {
			return marketingCampaign.toUpperCase();
		}
	});
	$("follow-subscribe[data-activity='marketingCampaign']").filter(function () {
		if($(this).data("name")) {
			return _contains(marketingCampaigns, ($(this).data("name").toUpperCase()));
		}
	}).addClass("disabled");
};

FollowSubscribe.updateSimilarComponent = function (ev, data) {
	var myLibary = $(".my-library").length > 0
	var newsletterPage = $(".newsletter-promo").length > 0
	if ((myLibary || newsletterPage) && data && data.type == "newsletter" || data.type == "marketingCampaign") {
		var $el = data.element;
		if ($el) {
			var name = $el.data("name");
			if (name) {
				if (data.disabled == 1 && newsletterPage) {
					$("follow-subscribe[data-name='" + name + "']").addClass("disabled");
				} else if (data.disabled == 0) {
					if (newsletterPage) {
						$("follow-subscribe[data-name='" + name + "']").removeClass("disabled");
					}
					var newsletterItems = $("follow-subscribe[data-name='" + name + "']").parents(".newsletter-tile-item")
					if (newsletterItems.length && $("#filter-newsletter") && $("#filter-newsletter").val() == "Subscribed") {
						newsletterItems.each(function() {
							if(!$(this).hasClass("newsletter-item-promo-container") && !$(this).parents(".otheEmails").length > 0) {
								$(this).removeClass("subscribed");
								$(this).addClass("do-not-show");
							}
						});
					}
				} 
			}
		}
	} 
};

mixinComponent(FollowSubscribe, "follow-subscribe");
state.addInitializer(FollowSubscribe);
SignIn.signInPromise.then(function() {
	FollowSubscribe.updateSubscriptions(user.getNewsletters());
	FollowSubscribe.updateFollowing(user.getFollowing());
	FollowSubscribe.updateMarketingCampaignSubscriptions(user.getMarketingCampaigns());
});

$("body").on("follow:update", FollowSubscribe.updateSimilarComponent);