import $ from "jquery";
import storageService from "../../../services/localstorage-util";
import moment from "moment";
import shajs from "sha.js";
import utilities from "support/utilities";

(function() {
	$("body").on("sign-in:success", setLoginInfo);
	$("body").on("register:success", setRegisterInfo);

	/**
	 * Set digital data on login operation.
	 *
	 * @param {object} ev - event object.
	 */
	function setLoginInfo (ev) {
		if (window.digitalData) {
			if (!utilities.isPianoOfferpage()) {
				// set event info
				var eventInfo = {};
				eventInfo.eventName = "Account Interaction";
				eventInfo.eventAction = "Account Login";
				var event = {};
				event.eventInfo = eventInfo;
				window.digitalData.event = [];
				window.digitalData.event.push(event);

				// set page type & name
				if (window.digitalData.page && window.digitalData.page.pageInfo) {
					window.digitalData.page.pageInfo.pageType = "My Account";
					window.digitalData.page.pageInfo.pageName = $("title").html();
				}
			}
			// get userprofile from local storage
			var userProfile = storageService.getCache("user");
			setUserInfo(userProfile);

			// set user attributes
			var profile = window.digitalData.users[0].profile;
			if (!profile.attributes) {
				profile.attributes = {};
				profile.attributes["industry"] = userProfile.industry;
				profile.attributes["company-size"] = userProfile.companySize;
				profile.attributes["job-title"] = userProfile.jobTitle;
				const focusSkillsObj = userProfile["leader-profile"] && userProfile["leader-profile"]["focus-skills"];
				const focusSkills = focusSkillsObj ? Object.values(focusSkillsObj) : [];
				if(focusSkills.length > 0) {
					profile.attributes.focusSkills = focusSkills
				}
			}
		}
	}

	/**
	 * Set digital data on register operation.
	 *
	 * @param {object} ev - event object.
	 * @param {string} pageName - page name value for this operation.
	 * @param {object} userProfile - userProfile object containing user information.
	 */
	function setRegisterInfo (ev, pageName, userProfile) {
		if (window.digitalData && userProfile) {
			if (!utilities.isPianoOfferpage()) {
				// set event info
				var eventInfo = {};
				eventInfo.eventName = "Account Interaction";
				eventInfo.eventAction = "Registration";
				var event = {};
				event.eventInfo = eventInfo;
				window.digitalData.event = [];
				window.digitalData.event.push(event);

				// set page type & name
				if (window.digitalData.page && window.digitalData.page.pageInfo) {
					window.digitalData.page.pageInfo.pageType = "Registration";
					window.digitalData.page.pageInfo.pageName = pageName;
				}
			}
			// set user info to digital data
			setUserInfo(userProfile);

			// set opt in status
			if (window.digitalData.users && window.digitalData.users.length > 0 && window.digitalData.users[0].profile) {
				var profile = window.digitalData.users[0].profile;
				if (!profile.attributes) {
					profile.attributes = {};
				}
				if (userProfile.optIn) {
					profile.attributes.optInStatus = true;
				} else {
					profile.attributes.optInStatus = false;	
				}
			}
		}
	}

	/**
	 * Set user info to digital data.
	 *
	 * @param {object} userProfile - userProfile object containing user information.
	 */
	function setUserInfo (userProfile) {
		if (window.digitalData.users && window.digitalData.users.length > 0 && window.digitalData.users[0].profile && userProfile) {
			var outputDateFormat = "YYYY-MM-DD";
			
			// set profile details
			var profile = window.digitalData.users[0].profile;
			profile.accountID = userProfile.id;
			profile.guid = userProfile.guid;
			profile.userState = "Registered";
			profile.username = userProfile.emailAddress;
			// set hashed email
			profile.hashedEmail = shajs("sha256").update(userProfile.emailAddress, "utf8").digest("hex");
			// set quota
			profile.quota = {};
			if (userProfile.quota) {
				profile.quota.limit = userProfile.quota.limit;
				profile.quota.remaining = userProfile.quota.remaining;
			}

			if (userProfile.sptCustomAttributes) {
				profile.test_experiences = [];
				userProfile.sptCustomAttributes.forEach(function (item) {
					if ("adobeTargetActivity" == item.name) {
						let activity = 	{};
						let testInfo = item.value.split("|");
						activity[testInfo[0]] = testInfo[1];
						profile.test_experiences.push(activity);
					}
				});
			}

			// set subscriber info
			if (userProfile.status === "subscribers") {
				profile.userState = "Subscriber";
				var currentSubscription = getCurrentSubscription(userProfile);

				if (currentSubscription) {
					var subscriberID = currentSubscription.accountNumber ? currentSubscription.accountNumber : userProfile.guid;
					profile.subscriberID = subscriberID;
					var subscriber = {};
					subscriber.status = currentSubscription.subscriptionStatus;
					subscriber.timesRenewed = currentSubscription.timesRenewed;
					subscriber.customerValue = currentSubscription.orderValue;
					subscriber.country = currentSubscription.country;
					subscriber.subscriberID = userProfile.subscriber ? userProfile.subscriber.id : "";
					subscriber.termTitle = currentSubscription.termTitle;
					if (currentSubscription.mostRecentPurchaseDate) {
						var mostRecentPurchaseDate = new Date(currentSubscription.mostRecentPurchaseDate);
						subscriber.mostRecentPurchaseDate = mostRecentPurchaseDate.getUTCFullYear() + "-" + mostRecentPurchaseDate.getUTCMonth() + "-" + mostRecentPurchaseDate.getUTCDate();	
					}
					if (currentSubscription.firstPurchaseDate) {
						var firstPurchaseDate = new Date(currentSubscription.firstPurchaseDate);
						subscriber.firstPurchaseDate = firstPurchaseDate.getUTCFullYear() + "-" + firstPurchaseDate.getUTCMonth() + "-" + firstPurchaseDate.getUTCDate();		
					}
					if (currentSubscription.subscriptionAccessPeriod) {
						if (currentSubscription.subscriptionAccessPeriod.startDate) {
							var date = new Date(currentSubscription.subscriptionAccessPeriod.startDate);
							subscriber.subscriptionStartDate = date.getUTCFullYear() + "-" + date.getUTCMonth() + "-" + date.getUTCDate();
						}
						if (currentSubscription.subscriptionAccessPeriod.expirationDate) {
							var date = new Date(currentSubscription.subscriptionAccessPeriod.expirationDate);
							subscriber.expirationDate = date.getUTCFullYear() + "-" + date.getUTCMonth() + "-" + date.getUTCDate();
						}
					}
					profile.subscriber = subscriber;
				}
			}

			// set registration date
			if (userProfile.createdDate) {
				profile.registrationDate = moment.utc(userProfile.createdDate).format(outputDateFormat);
			}

			// set last visit date and days since last visit
			if (userProfile.lastVisitDate) {
				var lastVisitDate = moment.utc(userProfile.lastVisitDate);
				var daysSinceLastVisit = moment.utc().diff(lastVisitDate, "days");
				if (daysSinceLastVisit < 1) {
					daysSinceLastVisit = "Less Than One Day";
				}
				profile.daysSinceLastVisit = daysSinceLastVisit;
				profile.dateLastVisit = lastVisitDate.format(outputDateFormat);
			}

			// set topic feeds information
			if (userProfile.following && userProfile.following.items && userProfile.following.items.length && userProfile.following.items.length > 0) {
				if (!profile.feeds) {
					profile.feeds = [];
				}
				$.each(userProfile.following.items, function(index, topicName) {
					var topic = {};
					topic.topicName = topicName;
					profile.feeds.push(topic);
				});
			}
		}
	}

	/**
	 * Get current subscriber account by checking most recent start date.
	 *
	 * @param {Object} current magazine subscription.
	 */
	function getCurrentSubscription (userProfile) {
		if (userProfile.subscriber && userProfile.subscriber.subscriptions && $.isArray(userProfile.subscriber.subscriptions)) {
			var currentSubscription = {};
			currentSubscription.subscriptionAccessPeriod = {}
			currentSubscription.subscriptionAccessPeriod.startDate = 0;

			// find magazine subscription with most recent start date
			$.each(userProfile.subscriber.subscriptions, function(index, subscription) {
				if (subscription.subscriptionSourceCode.toLowerCase() !== "ACCESS_CODE".toLowerCase() && subscription.subscriptionAccessPeriod.startDate > currentSubscription.subscriptionAccessPeriod.startDate) {
					currentSubscription = subscription;
				}
			});
		}
		return currentSubscription;
	}
})();
