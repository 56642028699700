export default {
	DEFAULT_STATE: "guest",
	REGISTERED_STATE: "registered",
	SUBSCRIBER_STATE: "subscriber",
	DEFAULT_STREAM: "latest",
	LOGIN_TRIES_LEFT: "tries_left",
	ACCOUNT_LOCKOUT_DURATION: "lockout_duration",
	USER_RESTRICTED: "user_restricted",
	MAX_STREAM_LOAD: 2,
	UNLIMITED_STREAM_LOADING_ENABLED: false,
	API: "/service/",
	STATIC: "/resources/static/",
	RESOURCES: "/resources/",
	MOBILE_BREAKPOINT: 767,
	ERROR_MESSAGE: "We are sorry. We are unable to process your request at this time. Customer Service is available 24/7. Phone: <a href='tel:18009880886'>1-800-988-0886</a> (<a href='tel:16177837500'>1-617-783-7500</a> outside the U.S. and Canada) or email at <a href='mailto:corpcustserv@hbsp.harvard.edu'>corpcustserv@hbsp.harvard.edu</a>",
	FORGOT_ERROR_MESSAGE: "We are sorry. We are unable to process your request at this time. Customer Service is available 24/7. Phone: 1-800-988-0886 (1-617-783-7500 outside the U.S. and Canada) or email at <a href='mailto:corpcustserv@hbsp.harvard.edu'>corpcustserv@hbsp.harvard.edu.</a>",
	FORGOT_ERROR_ACCOUNT_LOCK: "You cannot unlock your account by resetting the password. Please wait 24 hours to unlock and try again. For immediate assistance, please contact <a class='font-weight-semibold pointer primary-color' href='/subscriber-help#contact-customer-service'>Customer Service</a>.",
	ERROR_USER_PASSWORD: "You have entered an invalid email and password combination. Please try again.\r\nYou have %s tries left.",
	ERROR_ACCOUNT_LOCK: "Your account is blocked, please wait %s to unlock and try again. For immediate assistance, please contact <a class='font-weight-semibold pointer primary-color' href='/subscriber-help#contact-customer-service'>Customer Service</a>.",
	EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	PASSWORD_REGEX: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
	SIGN_IN_EMAIL_REGEX: /.*\S.*/,
	RESTRICTED_USER_MESSAGE: "We're having trouble verifying your account. Please contact our <a class='font-weight-semibold pointer primary-color' href='/subscriber-help#contact-customer-service'>Customer Service</a> team for assistance.",
};
