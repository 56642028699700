import React, { useEffect } from "react";
import FieldGroup from '../Form/FieldGroup';
import CaptchaService, { CaptchaActions } from "../../services/captcha-service";
import SubmitButton from '../Form/SubmitButton';
import { RequiredEmailField } from "../Form/InputField";
import ConditionalLoader from "../Commons/ConditionalLoader";
import passwordResetStore from "../../stores/authentication/password-reset";
import auth from "../../services/auth";

function ForgotPasswordForm(props) {
	const isValid = passwordResetStore((state) => state.valid);
	const form = passwordResetStore((state) => state.form)
	const isLoading = passwordResetStore((state) => state.isLoading)
	const setLoading = passwordResetStore((state) => state.setLoading)
	const reset = passwordResetStore((state) => state.reset);

	function verifyForgotPassword(token = null) {
		auth.verifyForgotPassword(form.email, token, "WEB_SITE")
			.then(function () {
				props.petitionAcknowledged()
				setLoading(false)
			}, function (data) {
				if (data.status === 403) {
					props.accountRestricted();
					setLoading(false)
				} else if (data.status == 423) {
					props.lockoutError()
					setLoading(false)
				}
			});
	}
	const onSubmit = (evt) => {
		evt.preventDefault();
		setLoading(true);
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					CaptchaService.executeRecaptcha(CaptchaActions.FORGOT_PASS).then((token) => {
						verifyForgotPassword(token);
					});
				} else {
					verifyForgotPassword(null);
				}
			});
	}

	useEffect(reset, []);
	return (
		<form class="form auth-content" onSubmit={onSubmit} id="modalForgotPasswordForm">
			<FieldGroup withLabel="Email" for="email">
				<RequiredEmailField
					name="email"
					{...props["email"]}
					store={passwordResetStore}
					/>
			</FieldGroup>
			<FieldGroup>
				<ConditionalLoader isEnabled={isLoading}>
					<SubmitButton
						value="Continue"
						enabled={isValid}
					/>
				</ConditionalLoader>
			</FieldGroup>
		</form>
	)
}

export default ForgotPasswordForm;
