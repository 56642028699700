{% if (entries.length !== 0) { entries.forEach(function(entry) { %}
<li class="stream-entry {% if (entry.contentType === 'Sponsored Content' || entry.contentType === 'Sponsor Content' || (entry.isSponsorContentType && entry.isSponsorContentType === 'true' && icFormat && entry.contentType === 'Research')) { %} sponsored {% } else if (entry.contentType === 'Research' && (typeof(entry.summary) == 'undefined' && entry.summary.indexOf('Sponsored content') > -1)) { %} analytics {% } %}">
	<div class="row">
		<div class="content-area column">
			{{ renderEntry(entry) }}
		</div>
	</div>
</li>
{% }); 
} else { 
	if (contentType === "watched") { %}
<section>
	<div class="row">
		<div class="content-area column">
			<div class="row">
				<div class="small-12 columns backdrop-cool-3 mtl">
					<div class="row">
						<div class="small-12 columns text-right mts">
						</div>
					</div>
					<div class="row">
						<div class="small-10 small-offset-1 columns mtl mbm text-center">
							<h3 class="subheader darker-gray text-center  mtm">Welcome to Your Watched History</h3>
							<h5 class="subheader darker-gray text-center mvl line-height-normal">Looks like you haven't watched any of our videos yet. Want to get started? Visit our video homepage</h5>
							<div class="mvl text-center">
								<a href="/video" class="button mtm mbl">VISIT OUR VIDEO PAGE</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
{% } if (contentType === "read") { %}
<section>
	<div class="row">
		<div class="content-area column">
			<div class="row">
				<div class="small-12 columns">
					<div class="row">
						<div class="small-12 columns text-right mtm">
						</div>
					</div>
					<div class="row">
						<div class="small-10 small-offset-1 columns mvm text-center">
							<h3 class="subheader darker-gray mtn">Welcome to Your Reading History</h3>
							<h5 class="subheader darker-gray mtm mbl line-height-normal">Looks like you haven't read anything since creating your account. Try visiting our homepage to see what our editors are focused on today, or check out content on one of our 150+ topics.</h5>

							<div class="mvl">
								<a href="/" class="button--black mrm">VISIT HOME PAGE</a>
									<a href="/topics" class="button--light-gray mrm">SEE OUR TOPICS</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
{% } if (contentType === "shared") { %}
<section>
	<div class="row">
		<div class="content-area column">
			<div class="row">
				<div class="small-12 columns">
					<div class="row">
						<div class="small-12 columns text-right mtm">
						</div>
					</div>
					<div class="row">
						<div class="small-10 small-offset-1 columns mvm text-center">
							<h3 class="subheader darker-gray mtn">Welcome to Your Sharing History</h3>
							<h5 class="subheader darker-gray line-height-normal">Looks like you haven't shared anything since creating your account. Use the share icon <small class="icon primary-color icon-share-square-o"></small> to easily share any piece of content via email or social media.</h5>							
							<div class="mvl">
								<a href="/" class="button--black mrm">VISIT HOME PAGE</a>
									<a href="/topics" class="button--light-gray mrm">SEE OUR TOPICS</a>
							</div> 
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
{% } if (contentType === "ordered") { %}
<section>
	<div class="row mt-large3">
		<div class="content-area column">
			<div class="row">
				<div class="small-10 small-offset-1 columns mt-large2 mbm text-center ">
					<h3 class="subheader darker-gray mtm">Welcome to Your Purchase History</h3>
					<h5 class="subheader darker-gray mtm mbl line-height-normal">You haven't purchased anything yet. Visit our store to start shopping.</h5>
					<div class="mvl text-center">
						<a href="/store" class="button--black mtm mbl">Visit our store</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
{% } if (contentType === "topics") { %}
	<li>
		<h3 class="subheader darker-gray mtm text-center">Welcome to your Topics, <strong id="user_name"></strong></h3>
		<p class="subheader darker-gray mbl mtm text-center line-height-normal font-size-18 font-gt-america">Welcome to your topic feeds, where you follow any of our 180+ topics and get a fresh daily dose of <em>Harvard Business Review</em> content related to your interests. To get started, select a few topics you're interested in. You can explore all of our topics using the "Find More" button to the left.</p>
		<div class="row mt-large2">
			{% [["Strategy", "Negotiation strategies", "Managing Yourself", "Economics"], ["Leading Teams", "Decision making and problem solving", "Innovation", "Marketing"]].forEach(function(names) { %}
				<div class="small-12 medium-6 columns">
					{% names.forEach(function(name) { %}
						<div class="row mtm">
							<div class="small-7 columns"><h5>{{name}}</h5></div>
							<div class="small-5 columns">
								<follow-subscribe class="follow-topic" data-name="{{name}}" data-activity="topic" data-facet="subject">
									<div class="follow-this"><a role="button" aria-label="follow {{name}}" href="#" js-target="enable-this" data-method="follow"><i class="icon font-size-xsmall icon-follow-this"></i> Follow this</a></div>
									<div class="unfollow-this"><a a role="button" aria-label="unfollow {{name}}" href="#" js-target="disable-this"  data-method="unfollow"><i class="icon font-size-xsmall icon-unfollow-this"></i> Following</a></div>
								</follow-subscribe>
							</div>
						</div>
					{% }); %}
				</div>
			{% }); %}
		</div>
		<a href="#" class="button--gray mtl expand-when-small" js-target="reload-stream-list">Done</a>
	</li>
{% } if (contentType === "newFolderCreated") { %}
	<section>
		<div class="row">
			<div class="content-area column">
				<div class="row">
					<div class="small-10 small-offset-1 columns mtl mbm text-center">
						<h3 class="subheader darker-gray">You've just created a new folder</h3>
						<h5 class="subheader darker-gray mtm mbl line-height-normal">Use folders to organize the content you save and share with colleagues. Use the folder icon <small class="icon icon-file-text-o"></small> on any piece of content to add it to a folder.</h5>
						<a href="/" class="button--black mrm">Go to Home Page</a>
						<a href="/topics" class="button--black mrm">See Our Topics</a>
					</div>
				</div>
			</div>
		</div>
	</section>
{% } if (contentType === "library") { %}
	<section>
		<div class="row">
			<div class="content-area column">
				<div class="row">
					<div class="small-12 columns mtl">
						<div class="row">
							<div class="hbr-checkbox left mrl mln hide-for-small"></div>
							<div class="small-10 columns text-center">
									<h3 class="subheader darker-gray">Hi, <strong>{{user.getFirstName()}}</strong>. Welcome to your personal HBR Library.</h3>
									<h5 class="subheader darker-gray mtm mbl line-height-normal">Consider this your own personal space for collecting, organizing, and sharing your favorite content from Harvard Business Review. Use the folder icon <small class="icon icon-file-text-o"></small> on any piece of content to add it to your library.</h5>
									  <a href="/" class="button--black">Go to Home Page</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>	
{% } if (contentType === "activity") { %}
<section>
		<div class="row">
			<div class="content-area column">
				<div class="row">
					<div class="small-12 columns">
						<div class="row">
							<div class="small-10 small-offset-1 columns mtl mbm text-center">
								<h3 class="subheader darker-gray mtn">Welcome to Your Activity Feed</h3>
								<h5 class="subheader darker-gray mtm mbl line-height-normal">Here you can find all the things that you've read and shared while signed in to your account. To get started, visit our homepage to see what our editors are focused on today, or check out content on one of our 150+ topics.</h5>
								  
								<div class="mvl">  
									<a href="/" class="button--black mrm">VISIT HOME PAGE</a>
									<a href="/topics" class="button--light-gray mrm">SEE OUR TOPICS</a>
								</div>
							</div>							
						</div>
					</div>
				</div>
			</div>
		</div>
</section>
{% } if (contentType === "content") { %}
	<section>
		<div class="row">
			<div class="content-area column">
				<div class="row">
					<div class="small-12 columns backdrop-cool-3 mtl">
						<div class="row">
							<div class="small-10 small-offset-1 columns mtl mbm">
								<h3 class="subheader darker-gray text-center mvl">Looks like there is nothing here yet. To get started, click on the Harvard Business Review logo in the upper left corner to go to our homepage and see what our editors are focused on today.</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
{% }
} %}

{% if (!hide) { %}
<li class="stream-entry load-more">
	<div class="row">
		<div class="content-area column">
			<div class="load-ten-more">
				<a href="#" js-target="load-ten-more-link">
				<i class="icon font-size-xsmall icon-load-more mrs"></i> Load more items</a>
			</div>
		</div>
	</div>
</li>
{% } %}

{% if (icFormat) { %}
<li class="feed-pagination" role="navigation" aria-label="Pagination Navigation">
	<div class="page-list">
			<a class="previous-btn{% if (totalPages == 1) { %} hide-previous{% } %}{% if (currentPage == 1) { %} hide-previous{% } %}"
				js-target="click-pagination"
				role="button"
				tabindex="0"
				aria-label="Previous"
			>
			Previous
			</a>

		{% pages.forEach(function(number,i) { %}
		<a
			key="{{ i }}"
			class="pagination-number{% if (currentPage == number ) { %} active{% } %}{% if (number == '...' ) { %} disable-click{% } %}"
			js-target="click-pagination"
			role="button"
			tabindex="0"
			>
			{{ number }}
		</a>
		{% }); %}


			<a class="next-btn{% if (totalPages == 1) { %} hide-next{% } %}{% if (currentPage == totalPages) { %} hide-previous{% } %}"
				js-target="click-pagination"
				role="button"
				tabindex="0"
				aria-label="Next"
			>
			Next
			</a>
	</div>
</li>
{% } %}
