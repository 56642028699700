
import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import dom from "react-dom-factories";
import _each from "lodash-amd/modern/collections/forEach";
import _map from "lodash-amd/modern/collections/map";
import _indexBy from "lodash-amd/modern/collections/indexBy";
import _omit from "lodash-amd/modern/objects/omit";
import _values from "lodash-amd/modern/objects/values";
import _sortBy from "lodash-amd/modern/collections/sortBy";
import interaction from "services/hbr/interaction";
import $ from "jquery";

var TopicMenu = createReactClass({
	getInitialState: function() {
		return {topics: {}, open: false, active: "all", deleting: false, topicsLoaded: true};
	},

	loadTopics: function() {
		interaction.request("topic", "GET").then(function(data) {
			for (let i in data) {
				data[i].name = data[i].name.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
			}
			var newTopics = _indexBy(data, "id");
			this.setState({topics: newTopics, active: newTopics[this.state.active] ? this.state.active : "all"});
		}.bind(this));
	},

	componentDidMount: function() {
		this.loadTopics();
	},

	componentDidUpdate: function(prevProps, prevState) {
		if (this.state.active !== prevState.active) {
			$(ReactDOM.findDOMNode(this)).trigger("topic-menu:change", this.state.active === "all" ? {id: "all"} : this.state.topics[this.state.active]);
		}
	},

	onOpen: function(ev) {
		ev.preventDefault();
		this.setState({open: !this.state.open});
	},

	onActivate: function(topic, ev) {
		ev.preventDefault();
		this.setState({active: topic.id, open: false, deleting: false});
	},

	onDelete: function(topic, ev) {
		ev.preventDefault();
		this.setState({active: topic.id, deleting: true});
	},

	onDeleteYes: function(topic, ev) {
		ev.preventDefault();
		interaction.request("topic", "delete", {name: topic.name}).then(function() {
			this.setState({active: "all", deleting: false, topics: _omit(this.state.topics, topic.id)});
		}.bind(this));
	},

	onDeleteNo: function(ev) {
		ev.preventDefault();
		this.setState({deleting: false});
	},

	render: function() {
		return dom.div({className: "folder-list" + (this.state.open ? " open" : "")},
			dom.a({href: "#", className: "folder-list--opener flex-row justify-between pvm prm pll link--white", onClick: this.onOpen},
				this.state.active === "all" ? "Everything" : this.state.topics[this.state.active].name,
				dom.span({className: "icon icon-caret-right font-size-xlarge"})
			),
			dom.div({className: "folder-list--folders"},
				_map([{id: "all", name: "Everything"}].concat(_sortBy(_values(this.state.topics), "name")), function(topic) {
					var active = this.state.active === topic.id;
					var deleting = active && this.state.deleting;
					return dom.div({className: "folder-list--folder prm" + (active ? " active" : "") + (deleting ? " editing" : "")},
						deleting ? dom.div({className: "ptm pbl"},
							dom.div({className: "mbm"}, "Stop following \"" + topic.name + "\"?"),
							dom.div({className: "row"},
								dom.div({className: "small-6 columns"},
									dom.button({className: "button--black expand", onClick: this.onDeleteYes.bind(this, topic)}, "Yes")
								),
								dom.div({className: "small-6 columns"},
									dom.button({className: "button--alt-black expand", onClick: this.onDeleteNo}, "No")
								)
							)
						) : dom.div({className: "flex-row"},
							dom.a({href: "#", className: "folder-list--folder-link flex-1 pvm", onClick: this.onActivate.bind(this, topic)}, topic.name),
							topic.id !== "all"
								? dom.a({href: "#", className: "folder-list--edit-link icon icon-circle-x font-size-xsmall pvm mts", onClick: this.onDelete.bind(this, topic)})
								: ""
						)
					);
				}.bind(this)),
				dom.div({className: "folder-list--folder pvm prm"}, dom.a({href: "/topics", className: "mbn button--alt-black expand", "aria-label":"Find more about topics"}, "Find More"))
			)
		);
	}
});

var all;

TopicMenu.activateAll = function() {
	all = _map(document.getElementsByTagName("topic-menu"), function(el) {
		return ReactDOM.render(React.createElement(TopicMenu, null), el);
	});
};

TopicMenu.updateOnToggleTab = function () {
	_each(all, function(component) {
		var topicsFollowed = Object.keys(component.state.topics);
		// check if any topic is followed and no content has been loaded yet
		if (topicsFollowed.length > 0 && component.state.topicsLoaded === false) {
			$(ReactDOM.findDOMNode(component)).trigger("topic-menu:change", {id: "all"});
			component.setState({topicsLoaded: true});
		}
	});
};

$(document).on("topics:change", function() {
	_each(all, function(component) {component.loadTopics(); });
});

$(document).on("stream-list:loaded", function(ev, response) {
	if (response.streamEndpoint.match(/following/)) {
		_each(all, function(component) {
			component.setState({topicsLoaded: response.dataLength > 0});
		});
	}
});

export default TopicMenu;
