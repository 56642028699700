import React, { Component, useState, useEffect } from "react";
import auth from "../../services/auth";
import {Screen} from "../Authentication/Commons";
import ErrorMessage from "../Form/ErrorMessage";
import ForgotPasswordForm from "../Authentication/ForgotPasswordForm";
import passwordResetStore from "../../stores/authentication/password-reset";
import common from "../../common"
import ReactHtmlParser from 'react-html-parser';

function ForgotPasswordComponent(props) {
	const valid = passwordResetStore((state) => state.valid)
	const pristine = passwordResetStore((state) => state.pristine)
	const shouldRenderHelp = !valid && !pristine.email
	const [emailSent, setEmailSent] = useState(false);
	const [lockoutError, setLockoutError] = useState(false);
	const [accountRestricted, setAccountRestricted] = useState(false);
	if(emailSent) {
		setTimeout(props.onClose, 5000)
		return (
			<div class="auth-content forgot-password">
				<div class="wrapper">
					<h2 class="title with-mbl">Forgot your password?</h2>
					<p class="dek">If your email is registered with us, you will receive an email with a link to reset your password.</p>
				</div>
			</div>
		)
	} else if(lockoutError) {
		return (
			<div class="auth-content forgot-password">
				<div class="wrapper">
					<h2 class="title with-mbl">Forgot your password?</h2>
					<p class="dek">{ReactHtmlParser(common.FORGOT_ERROR_ACCOUNT_LOCK)}</p>
				</div>
			</div>
		)
	} else if(accountRestricted) {
		return (
			<div class="auth-content forgot-password">
				<div class="wrapper">
					<h2 class="title with-mbl">Forgot your password?</h2>
					<p class="dek">{ReactHtmlParser(common.RESTRICTED_USER_MESSAGE)}</p>
				</div>
			</div>
		)
    } else {
		return (
			<div class="auth-content forgot-password">
				<div class="wrapper">
					<h2 class="title with-mbs">Forgot your password?</h2>
					<p class="dek">Enter your email address and we'll send you a link to reset your password.</p>
					<div class="wrapper with-mtl">
						<ForgotPasswordForm 
							petitionAcknowledged={() => { setEmailSent(true)}}
							lockoutError={() => { setLockoutError(true)}}
							accountRestricted={() => { setAccountRestricted(true)}}
						/>
					</div>
					<div class="cta go-back">
						<a href="#" class="" onClick={(ev) => {ev.preventDefault(); props.sendTo(Screen.SIGN_IN)}}>
							<i class="icon icon-caret-left"></i>
							Go back
						</a>
					</div>
					{ shouldRenderHelp && 
						<div class="cta help">
							<a class="medium" href="/subscriber-help" target="_blank">Get Help</a>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default ForgotPasswordComponent;
