<div class="error-message" js-target="error-message">
	<div class="flex-row error-container">
		<div>
			<i class="icon icon-alert icon-lg font-size-18"></i>
		</div>
		<div class="message text-left font-size-14" js-target="notification-box-message">
			{{ message }}
		</div>
	</div>
</div> 
