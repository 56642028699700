import React, {Component} from 'react';
import PasswordControl from "./PasswordControl.jsx"
import ReactHtmlParser from 'react-html-parser';
import SignIn from "../../components/sign-in/sign-in";
import user from "../../models/user";
import { trigger } from "../../support/utilities";
import CaptchaService, {CaptchaActions} from "../../services/captcha-service";
import common from "../../common";
import $ from "jquery";

import _map from "lodash-amd/modern/collections/map";
import _indexBy from "lodash-amd/modern/collections/indexBy";

const captchaTarget = "captchaTarget";

class RegistrationComponent extends Component {
	constructor(props) {
		super(props);
		// State holds form data
		this.state = {
			form: {
				firstName: "",
				lastName: "",
				emailAddress: "",
				password: "",
				optIn: props.optIn,
				isCaptchaEnabled: false,
			},
			errors: [],
		}
	}

	componentDidMount() {
		$("body").trigger("piano:reg-start");
		this.handleRecaptchaNotice();
	}

	handleChange(event) {
		// A field value needs to be updated in state
		this.setState({
			form: { ...this.state.form, [event.target.name]: event.target.name == "optIn" ? event.target.checked : event.target.value	} // fieldName = fieldValue
		});
	}

	handleRecaptchaNotice = () => {
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					this.setState({isCaptchaEnabled: true});
				}
		});		
	}

	validateForm() {
		var errors = [];
		var errorMessage = "This field is required.";
		if (this.state.form.firstName.trim() === "") {
			errors.push({"field": "firstName", "errorMessage": errorMessage});
		}
		if (this.state.form.lastName.trim() === "") {
			errors.push({"field": "lastName", "errorMessage": errorMessage});
		}
		if (this.state.form.emailAddress.trim() === "") {
			errors.push({"field": "emailAddress", "errorMessage": errorMessage});
		} else {
			if (!this.state.form.emailAddress.trim().match(common.EMAIL_REGEX)) {
				errors.push({
					"field": "emailAddress",
					"errorMessage": "This email address is not valid."
				});
			}
		}
		if (this.state.form.password === "") {
			errors.push({"field": "password", "errorMessage": errorMessage});
		}
		this.setState({
			errors: errors.length ? _indexBy(errors, "field") : []
		});
		return errors.length;
	}

	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		const errors = this.validateForm();
		if (errors > 0) {
			return;
		}
		var _self = this;
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					CaptchaService.executeRecaptcha(CaptchaActions.REGISTER_SUB).then((token) => {
						user.pianoRegister({
							...this.state.form,
							reCaptchaToken: token,
							deviceType: "WEB_SITE"
						}).then(
							this.registerSuccess.bind(_self),
							this.handleRegistrationErrors.bind(_self),
						)
					});
				} else {
					user.pianoRegister({
						...this.state.form,
						reCaptchaToken: null,
						deviceType: "WEB_SITE"
					}).then(
						this.registerSuccess.bind(_self),
						this.handleRegistrationErrors.bind(_self),
					)
				}
			});
	}

	render() {
		let subscribeMessage = this.props.isGiftOffer ? "Sign me up for HBR email. You can unsubscribe at any time." : this.props.isUsDomUser ?
			" By registering, you agree to receive occasional emails from HBR. You may unsubscribe at any time." :
			" Sign up to receive occasional emails from HBR. You may unsubscribe at any time.";

		return (
			<div class="piano-card">
				<div class="header header-signin-reg">
					<span class="header-close-btn" onClick={() => this.props.onClose() }>
						<i className="hbr-icon icon-times"></i>
					</span>
					<h1 class="header-title title-signin-reg">{this.props.title}</h1>
					<spah class="header-subtitle subtitle-signin-reg">{this.props.subtitle}</spah>
				</div>
				<div class="body">
					<div className="wrapper">
						<h2 class="body-header">Create an account</h2>
						{this.props.isGiftOffer ? <p class="body-description">To send a gift, you'll need to create an HBR.org account.</p> : ""}
						<hr className="body-divider"/>
						<p class="alternate">Already have an account? <a onClick={() => this.props.toggle()}>Sign in</a></p>
						{this.getBannerError("common")}
						<form class="form" onSubmit={this.handleSubmit.bind(this)}>
							<div class="input-group half">
								<span className={this.isNoFieldError("firstName") ? "input-group-label" : "input-group-label label-error"}>{this.props.isGiftOffer ? "Your first name" : "First name"}</span>
								<input type="text" name= "firstName"
											 class={this.isNoFieldError("firstName") ? "text-input" : "text-input input-error"}
											 value={this.state.form.firstName} onChange={this.handleChange.bind(this)}/>
								{this.getError("firstName")}
							</div>
							<div class="input-group half">
								<span className={this.isNoFieldError("lastName") ? "input-group-label" : "input-group-label label-error"}>{this.props.isGiftOffer ? "Your last name" : "Last name"}</span>
								<input type="text" name="lastName"
											 class={this.isNoFieldError("lastName") ? "text-input" : "text-input input-error"}
											 value={this.state.form.lastName} onChange={this.handleChange.bind(this)}/>
								{this.getError("lastName")}
							</div>
							<div class="input-group">
								<span className={this.isNoFieldError("emailAddress") ? "input-group-label" : "input-group-label label-error"}>{this.props.isGiftOffer ? "Your email" : "Email"}</span>
								<input type="text" name="emailAddress"
											 class={this.isNoFieldError("emailAddress") ? "text-input" : "text-input input-error"}
											 value={this.state.form.emailAddress} onChange={this.handleChange.bind(this)}/>
								{this.getError("emailAddress")}
							</div>
							<div class="input-group">
								<span className={this.isNoFieldError("password") ? "input-group-label" : "input-group-label label-error"}>Password</span>
								<PasswordControl  value={this.state.form.password} error={!this.isNoFieldError("password")} handleChange={this.handleChange.bind(this)} />
								{this.getError("password")}
								<span class="password-requirements">Passwords must have at least 10 characters, one number, one upper and lower case letter, and one special character.</span>
							</div>
							<div className="input-group">
								<div id={captchaTarget} style={{marginBottom: "12px"}}></div>
								{this.getError("captcha")}
							</div>
							<div class="input-group">
								<label class="sing-up flex-row">
									<span className="mrs">
										<input type="checkbox" class="mbn" name="optIn" value={this.state.form.optIn} onChange={this.handleChange.bind(this)} defaultChecked={this.props.isUsDomUser ? true : false}/>
									</span>
									<span>{ subscribeMessage }<a href="/privacy-policy" target="_blank" > View our privacy policy</a></span>
								</label>
							</div>
							<div class="input-group clear">
								<a href="#" className="btn-back piano-reg-back-btn" onClick={() => this.props.navigateToGoBack()} ><i className="icon icon-caret-left"></i> Go back</a>
								<input class="f-r" type="submit" value={this.props.isGiftOffer ? "On to recipient" : "On to checkout"}/>
							</div>
						</form>
						{this.state.isCaptchaEnabled && <div class="recaptcha-privacy-notice">
							This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
							</div>
						}
					</div>
				</div>
			</div>
		);
	}

	getError(fieldName) {
		var errorMessage = "";
		if (this.state.errors && this.state.errors[fieldName]) {
			errorMessage = this.state.errors[fieldName].errorMessage;
		}

		return errorMessage ?
				<span class="error-item">
					<span class="icon-alert"></span>
					<span class="error-message">
						{errorMessage}
					</span>
				</span> : "";
	}

	getBannerError(fieldName) {
		var errorMessage = "";
		if (this.state.errors && this.state.errors[fieldName]) {
			errorMessage = this.state.errors[fieldName].errorMessage;
		}

		return errorMessage ? <span class="error-message">{errorMessage}</span> : "";
	}

	getRegistrationConflictTemplate() {
		return (
			<span> That username is unavailable.  <a onClick={() => this.props.toggle()}> Sign in</a> or choose another email address.
				Forgot your password? <a onClick={() => this.props.navigateToForgotPassword()}>Reset it here.</a>
			</span>
		);
	}

	registerSuccess({user, header}) {
		if (header) {
			// See HBROPS-3079 for this flow
			SignIn.signInPromise.resolve(user);
			this.props.onClose();
			$("body").trigger("piano:registration-success");
		} else {
			trigger("metrics:registration_success", {user: user});
			SignIn.signInPromise.resolve(user);

			if (user) {
				user.optIn = this.state.optIn;
			}
			$("body").trigger("register:success", ["Registration - Modal", user]);

			// if there is a paywall in this page, reload the page
			if ($("site-paywall").length > 0) {
				window.location.reload();
			}

			// Unmount component
			this.props.onClose();
			$("body").trigger("piano:reg-success");
		}
	}

	handleRegistrationErrors(data) {
		trigger("metrics:registration_failure", {errors: _map(data.responseJSON.errors || [], "field")});
		if (!data.responseJSON.errors) {
			data.responseJSON.errors = [];
		}

		// pick email address error message
		var hasEmailAddessError, hasPasswordError;
		var passwordErrorMessage;
		data.responseJSON.errors.forEach(element => {
			if (element.field == "emailAddress") {
				hasEmailAddessError = true;
			}

			if (element.field == "password") {
				hasPasswordError = true;
				passwordErrorMessage = element.errorMessage;
			}
		});

		var err;
		if (data.status === 409) {
			err = {"field": "emailAddress", "errorMessage": this.getRegistrationConflictTemplate()};
		} else if (data.status === 400 && hasEmailAddessError) {
			err = {"field": "emailAddress", "errorMessage": "Email address is not valid."};
		} else if (data.status === 400 && hasPasswordError) {
			err = {"field": "password", "errorMessage": passwordErrorMessage};
		} else if (data.status === 403) {
			// First check if "forbidden" is in the errors.
			// If so then go to the /restricted-user page
			window.location.href = "/restricted-user";
		} else {
			err = {"field": "common", "errorMessage": (
						<div className="error-item-banner">
							<span className="icon-alert icon-alert-banner"></span>
							<div className="error-message-banner">
								{ReactHtmlParser(common.ERROR_MESSAGE)}
							</div>
						</div>
				)};
		}

		data.responseJSON.errors = [];
		data.responseJSON.errors.push(err);
		this.setState({
			// button: "ready",
			errors: data.responseJSON.errors && data.responseJSON.errors.length ? _indexBy(data.responseJSON.errors, "field") : data.message,
		});
		$("body").trigger("piano:reg-failure");
	}

	isNoFieldError(fieldName) {
		return !((fieldName in this.state.errors) || ("common" in this.state.errors));
	}
}

export default RegistrationComponent;
