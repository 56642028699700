import $ from "jquery";

const actions = {
	FORGOT_PASS: "forgot_pass",
	FORGOT_PASS_MODAL: "forgot_pass_modal",
	FORGOT_PASS_PAYWALL: "forgot_pass_paywall",
	FORGOT_PASS_SUB: "forgot_pass_sub",
	FORGOT_PASS_SYNC_SUB: "forgot_pass_sync_sub",
	REGISTER: "register",
	REGISTER_MODAL: "register_modal",
	REGISTER_PAYWALL: "register_paywall",
	REGISTER_SUB: "register_sub",
	SIGN_IN: "sign_in",
	SIGN_IN_MODAL: "sign_in_modal",
	SIGN_IN_PAYWALL: "sign_in_paywall",
	SIGN_IN_SUB: "sign_in_sub",
	SIGN_IN_SYNC_SUB: "sign_in_sync_sub"
};
const keys = {
	score: window.captchaScoreKey,
};
let isCaptchaEnabled = null;

class CaptchaService {
	static isCaptchaEnabled() {
		return new Promise((resolve) => {
			if (isCaptchaEnabled === null) {
				$.ajax({url: "/api/recaptcha/enabled"})
					.then(
						(isEnabled) => {
							isCaptchaEnabled = isEnabled;
						},
						() => {
							isCaptchaEnabled = true;
						}
					)
					.always(() => {
						resolve(isCaptchaEnabled);
					});
			} else {
				resolve(isCaptchaEnabled);
			}
		});
	}

	/**
   * Executes reCAPTCHA and returns a token.
   *
   * @memberof CaptchaService
   * @static
   * @param {string} action - The action to execute (e.g., 'submit').
   * @returns {Promise<string>} - The reCAPTCHA token.
   */
	static async executeRecaptcha(action) {
		return new Promise((resolve, reject) => {
			if (window.grecaptcha && window.grecaptcha.enterprise) {
				window.grecaptcha.enterprise.ready(() => {
					window.grecaptcha.enterprise
						.execute(keys.score, {action: action})
						.then((token) => {
							resolve(token);
						})
						.catch((error) => {
							reject(error);
						});
				});
			} else {
				reject(new Error("Error executing grecaptcha: grecaptcha is not available."));
			}
		});
	}
}

export {
	CaptchaService as default,
	keys as CaptchaKeys,
	actions as CaptchaActions,
};
